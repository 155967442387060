export interface ImageType {
  id?: string;
  path: string;
  title?: string;
}

export interface VideoPlayerType {
  path: string;
  poster: ImageType;
}

export enum MediaTypeEnum {
  IMAGE = 'ImageType',
  VIDEO = 'VideoPlayerType',
}

export interface ImageOrVideoType {
  id?: string;
  type: MediaTypeEnum;
  image?: ImageType;
  video?: VideoPlayerType;
  title?: string;
}

export interface CallToActionType {
  label: string;
  path: string;
  blank?: boolean;
}

// Section types
export interface LessonHeroData {
  header?: string;
  subHeader?: string;
  sectionDivider: boolean;
  backgroundImage?: ImageType;
  video?: VideoPlayerType;
  paragraph?: string;
  callToAction?: CallToActionType;
}

export interface LinkSectionData {
  header?: string;
  subHeader?: string;
  paragraph?: string;
  callToAction?: CallToActionType;
}

export interface ListSectionItem {
  id: string;
  content: string;
}
export interface ListSectionData {
  header?: string;
  subHeader?: string;
  paragraph?: string;
  backgroundColor?: string;
  image?: ImageType;
  list?: ListSectionItem[];
}
export interface QuoteSectionData {
  header?: string;
  subHeader?: string;
  author?: string;
  image?: ImageType;
}
export interface TextSectionData {
  header?: string;
  subHeader?: string;
  paragraph?: string;
  callToAction?: CallToActionType;
}
export interface MultimediaSectionData {
  header?: string;
  subHeader?: string;
  paragraph?: string;
  callToAction?: CallToActionType;
  media?: ImageOrVideoType;
  backgroundColor?: string;
}
export interface GalleryData {
  header?: string;
  subHeader?: string;
  media?: ImageOrVideoType[];
}
export interface VideoSectionData {
  header?: string;
  subHeader?: string;
  video?: VideoPlayerType;
}

export interface CarouselSectionData {
  header?: string;
  subHeader?: string;
  media?: ImageOrVideoType[];
  showIndex?: boolean;
}
export interface CheckboxItem {
  id?: string;
  label?: string;
  checked?: boolean;
}

export interface CheckboxSectionUserData {
  blockId: string;
  allChecked: boolean;
  lessonId?: string;
  moduleId?: string;
  courseId?: string;
  checkList: CheckboxItem[];
}

export interface CheckboxData {
  header?: string;
  subHeader?: string;
  list?: CheckboxItem[];
}
