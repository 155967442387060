import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

// Import components
import { ReactComponent as PlayVideoIcon } from 'assets/svg-icons/play-video-icon.svg';
import { Image } from 'components/Image';
import { FreeContentType } from 'common/types/FreeContent.type';

// Import styles
import styles from './FreeContentCard.module.scss';

type Props = {
  content: FreeContentType;
};

export const FreeContentCard = ({ content }: Props): ReactElement => {
  return (
    <Link to={`/content/${content.id}`}>
      <div className={`relative flex w-80 flex-col gap-2 ${styles.FreeContentCard}`}>
        <div className="relative">
          <div className="aspect-w-2 aspect-h-3 h-full overflow-hidden rounded-2xl">
            <Image
              src={content.content.data.posterImage}
              alt={content.content.data.posterImageTitle}
            />
          </div>
          <div
            className={`play-button absolute top-0 left-0 bottom-0 right-0 flex h-full w-full items-center justify-center rounded-2xl align-middle ${styles.PlayButton}`}
          >
            <PlayVideoIcon />
          </div>
        </div>
        <h1 className="mt-2 max-h-16 w-full text-ellipsis text-xl font-bold text-primary ">
          {content.title}
        </h1>
      </div>
    </Link>
  );
};

export default FreeContentCard;
