import React, { ReactElement, useState } from 'react';

// Import components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

// Interfaces
export interface ImageProps {
  className?: string;
  imageClassName?: string;
  src: string;
  alt: string;
  zoomable?: boolean;
}

export const Image = ({
  className = '',
  imageClassName = '',
  src,
  alt,
  zoomable = false,
}: ImageProps): ReactElement => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div
      className={`items-center justify-center overflow-hidden object-cover align-middle ${className}`}
    >
      {zoomable && (
        <div
          onClick={toggleModal}
          className="absolute z-10 flex h-full w-full cursor-pointer items-center justify-center bg-transparent align-middle opacity-0 transition-opacity hover:opacity-70"
        >
          <FontAwesomeIcon
            className="h-auto text-white"
            style={{ width: '20%', maxWidth: '60px' }}
            icon={faSearch}
          />
        </div>
      )}
      <img className={`h-full w-full object-cover ${imageClassName}`} src={src} alt={alt} />
      {showModal && (
        <div
          onClick={toggleModal}
          style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}
          className="fixed top-0 left-0 right-0 z-50 flex h-full w-full cursor-pointer items-center justify-center align-middle backdrop-blur-lg backdrop-filter"
        >
          <img src={src} className="h-auto max-h-full w-auto max-w-full" alt="Zoomed" />
        </div>
      )}
    </div>
  );
};
export default Image;
