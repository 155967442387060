// Lesson blocks
export enum LESSON_BLOCK_TYPE {
  LESSON_HERO = 'LESSON_HERO',
  LINK_SECTION = 'LINK_SECTION',
  LIST_SECTION = 'LIST_SECTION',
  QUOTES_SECTION = 'QUESTION_SECTION',
  TEXT_SECTION = 'TEXT_SECTION',
  MULTIMEDIA_SECTION = 'MULTIMEDIA_SECTION',
  CAROUSEL_SECTION = 'CAROUSEL_SECTION',
  MEDIA_GALLERY = 'MEDIA_GALLERY',
  VIDEO_SECTION = 'VIDEO_SECTION',
  CHECKBOX_FORM = 'CHECKBOX_FORM',
}

export interface LessonBlock<T> {
  id: string;
  version: string;
  type: LESSON_BLOCK_TYPE;
  data: T;
}

// Building Block Form types
export interface BlockFormHandle {
  submit: () => void;
  reset: () => void;
}

export interface BlockFormProps<T> {
  data: LessonBlock<T> | null;
  onSubmit: (data: T) => void;
}
