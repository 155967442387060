import { ReactElement, ReactNode } from 'react';

// Import Component
import Header from 'components/Header/Header';
import Paragraph from 'components/Paragraph/Paragraph';
import { Button } from 'components/Button';
import { ReactComponent as FailedIcon } from 'assets/svg-icons/failed-icon.svg';
import { ReactComponent as SuccessIcon } from 'assets/svg-icons/success-icon.svg';

interface ButtonProps {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: () => any;
}

interface PaymentProps {
  className?: string;
  title: string;
  message: string | ReactNode;
  additionalMessage: string | ReactNode;
  isSuccess: boolean;
  children?: ReactNode;
  button?: ButtonProps;
}

const PaymentFailed = ({
  title,
  message,
  additionalMessage,
  className,
  isSuccess,
  children,
  button,
}: PaymentProps): ReactElement => {
  return (
    <div
      className={`relative  flex h-full min-h-screen w-full flex-col items-center justify-center py-16 xl:py-0 ${className}`}
    >
      <div className="absolute top-0 left-0 right-0 h-full w-full overflow-hidden">
        <img
          className="opacity-1 min-h-full min-w-full object-cover"
          src="/assets/img/bg-img.jpg"
          alt="Checkout Background"
        />
      </div>
      <div className="relative w-11/12 md:w-full md:max-w-2xl lg:max-w-3xl xl:max-w-5xl 2xl:max-w-7xl">
        <img
          className="absolute top-0 left-0 z-20 -translate-x-1/2 -translate-y-1/3 scale-50 transform object-cover md:-translate-x-1/2 md:-translate-y-1/2 lg:scale-100"
          src="/assets/img/leaf-topleft.svg"
          alt="Checkout Background"
        />
        <img
          className="absolute right-0 top-0 z-0 translate-x-16 -translate-y-1/2 scale-50 transform object-cover md:translate-x-1/2 md:-translate-y-1/2 lg:scale-100"
          src="/assets/img/leaf-topright.svg"
          alt="Checkout Background"
        />
        <img
          className="absolute bottom-0 left-0 z-20 -translate-x-10 translate-y-1/2 scale-50 transform object-cover lg:scale-100"
          src="/assets/img/leaf-botleft.svg"
          alt="Checkout Background"
        />
        <img
          src="/mtg-logo.png"
          className="absolute left-0 right-0 -top-10 mx-auto -translate-y-full transform"
          alt="MTG Logo"
        />
        <div className="z-10 w-full rounded-4xl bg-white bg-opacity-75 bg-cover px-8 py-10 shadow backdrop-blur backdrop-filter">
          <div className="mx-auto mb-7 w-fit">{isSuccess ? <SuccessIcon /> : <FailedIcon />}</div>
          <div className="w-full md:mx-auto md:w-3/4 lg:w-1/2">
            <Header className="mb-2 text-center" title={title} />
            <Paragraph className="mb-7 text-center" fontWeight="font-thin" text={message} />
            <Paragraph
              className="mb-7 text-center"
              fontWeight="font-thin"
              text={additionalMessage}
            />
            {button && (
              <Button
                size="2xl"
                round="4xl"
                full={false}
                background={isSuccess ? 'green' : 'black'}
                color="white"
                title={button.title}
                onClick={button.onClick}
                className="mx-auto"
              />
            )}
            {children ? children : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailed;
