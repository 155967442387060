import { ReactElement, useEffect, useRef } from 'react';
import { Routes, Route, Link, useLocation, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Types
import { Course } from 'common/types/Course.type';

// Config
import routes from 'config/routesConfig';
// Import components
import { CourseModules } from 'components/Courses/CourseDetails/CourseModules/index';
import { CourseOverview } from 'components/Courses/CourseDetails/CourseOverview/index';
import { Alert } from 'components/Alert';

export interface Props {
  course: Course;
  loading: boolean;
  error: string;
}

export default function CourseDetails({
  course: { studentIsEnrolled, ...course },
  loading,
  error,
}: Props): ReactElement {
  const location = useLocation();
  const isOverview = location.pathname.includes('overview');
  const isModules = location.pathname.includes('modules');

  const { t } = useTranslation('components');

  const lessonsSectionRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (location.hash === '#lessons') {
      if (lessonsSectionRef.current) {
        lessonsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <div className="relative -mt-1">
      <Alert message={error} />
      <div
        ref={lessonsSectionRef}
        id="lessons"
        className="relative z-10 flex w-full justify-center bg-black-100 px-5 py-10 md:justify-start md:px-36"
      >
        <Link
          to={`/course/${course.id}/overview`}
          className={`
              relative cursor-pointer px-5 pb-3 pl-0 text-center text-2xl text-white md:px-10 md:pl-0 md:text-4xl.5
              ${!isOverview ? 'font-normal opacity-50' : 'font-medium'}
            `}
        >
          {t('courses.overview')}
          {isOverview && (
            <div className="green-gradient absolute left-0 bottom-0 h-1 w-full translate-y-full transform md:h-1.5 " />
          )}
        </Link>

        <Link
          to={`/course/${course.id}/modules`}
          className={`
              cursor-pointer' relative px-5 pb-3 text-center text-2xl text-white md:px-10 md:text-4xl.5
              ${!isModules ? 'font-normal opacity-50' : 'font-medium'}
            `}
        >
          {t('courses.module')}
          {isModules && (
            <div className="green-gradient absolute left-0 bottom-0 h-1 w-full translate-y-full transform md:h-1.5 " />
          )}
        </Link>
      </div>

      <div className="relative z-0">
        <Routes>
          <Route
            path={routes.course.overview.path}
            element={<CourseOverview overviewBlocks={course.overviewBlocks} />}
          />
          <Route
            path={routes.course.modules.path}
            element={
              <CourseModules
                courseId={course.id}
                loading={loading}
                modules={course.modules || []}
                studentIsEnrolled={studentIsEnrolled || false}
              />
            }
          />
          <Route path="/*" element={<Navigate to={`/course/${course.id}/overview`} />} />
        </Routes>
      </div>
    </div>
  );
}
