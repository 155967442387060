import React, { ReactElement } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

// Import config
import routes from 'config/routesConfig';

// Import partials
import ProfileNavigation from './SettingsNavigation';
import MyProfilePage from './MyProfilePage';
import CoursesPage from './CoursesPage';
import SettingsEdit from './SettingsEdit';
import PaymentPage from './PaymentPage';
import EditProfilePage from './EditProfilePage';
import CertificatesPage from './CertificatesPage';

import { TabLinks } from 'components/TabLinks';

const SettingsPage = (): ReactElement => {
  return (
    <div>
      <TabLinks />
      <div className="relative mx-4 my-16 flex flex-col rounded-2xl bg-white p-5 md:bg-transparent lg:mx-36 lg:flex-row lg:p-0 ">
        <ProfileNavigation className="w-full lg:w-1/6 " />
        <div className="hidden lg:flex lg:w-5/6">
          <Routes>
            <Route
              path={routes.settings.profile.path}
              element={<MyProfilePage className="mt-10 w-full flex-1 md:pl-10 lg:mt-0 lg:w-5/6" />}
            />
            <Route
              path={routes.settings.courses.path}
              element={<CoursesPage className="mt-10 w-full flex-1 md:pl-10 lg:mt-0 lg:w-5/6" />}
            />
            <Route
              path={routes.settings.edit.path}
              element={<SettingsEdit className="mt-10 w-full flex-1 md:pl-10 lg:mt-0 lg:w-5/6" />}
            />
            <Route
              path={routes.settings.editProfile.path}
              element={
                <EditProfilePage className="mt-10 w-full flex-1 md:pl-10 lg:mt-0 lg:w-5/6" />
              }
            />
            <Route
              path={routes.settings.payment.path}
              element={<PaymentPage className="mt-10 w-full flex-1 md:pl-10 lg:mt-0 lg:w-5/6" />}
            />
            <Route
              path={routes.settings.certificates.path}
              element={
                <CertificatesPage className="mt-10 w-full flex-1 lg:mt-0 lg:w-5/6 lg:pl-10" />
              }
            />
            <Route
              path="*"
              element={
                <Navigate to={`${routes.settings.index.path}${routes.settings.profile.path}`} />
              }
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
