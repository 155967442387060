import { ReactElement, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import types
import { APIError } from 'utils/api';
import { Lesson } from 'common/types/Lesson.type';
import { LESSON_BLOCK_TYPE } from 'common/types/BuildingBlock';
import { CheckboxItem, CheckboxSectionUserData } from 'common/types/RenderEngine.type';

// Import services
import lessonService from 'services/lesson.service';
import userService from 'services/user.service';

// Import config
import routes from 'config/routesConfig';

// Import component
import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import { AbosluteSpinner } from 'components/Spinners';
import LessonHero from 'components/RenderEngine/LessonHero/LessonHero';
import LinkSection from 'components/RenderEngine/LinkSection/LinkSection';
import ListSection from 'components/RenderEngine/ListSection/ListSection';
import QuotesSection from 'components/RenderEngine/QuotesSection/QuotesSection';
import TextSection from 'components/RenderEngine/TextSection/TextSection';
import MultimediaSection from 'components/RenderEngine/MultimediaSection/MultimediaSection';
import CarouselSection from 'components/RenderEngine/CarouselSection/CarouselSection';
import Gallery from 'components/RenderEngine/Gallery/Gallery';
import VideoSection from 'components/RenderEngine/VideoSection/VideoSection';
import CheckboxForm from 'components/RenderEngine/CheckboxForm/CheckboxForm';

export const BUILDING_BLOCKS = {
  [LESSON_BLOCK_TYPE.LESSON_HERO]: LessonHero,
  [LESSON_BLOCK_TYPE.LINK_SECTION]: LinkSection,
  [LESSON_BLOCK_TYPE.LIST_SECTION]: ListSection,
  [LESSON_BLOCK_TYPE.QUOTES_SECTION]: QuotesSection,
  [LESSON_BLOCK_TYPE.TEXT_SECTION]: TextSection,
  [LESSON_BLOCK_TYPE.MULTIMEDIA_SECTION]: MultimediaSection,
  [LESSON_BLOCK_TYPE.CAROUSEL_SECTION]: CarouselSection,
  [LESSON_BLOCK_TYPE.MEDIA_GALLERY]: Gallery,
  [LESSON_BLOCK_TYPE.VIDEO_SECTION]: VideoSection,
  [LESSON_BLOCK_TYPE.CHECKBOX_FORM]: CheckboxForm,
};

const LessonPage = (): ReactElement => {
  // Router
  const { courseId, moduleId, lessonId } = useParams();
  const navigate = useNavigate();

  // State
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [lesson, setLesson] = useState<Lesson>();
  const [hasNextLesson, setHasNextLesson] = useState(false);
  const userLessonDataList = lesson?.userLessonDataList || [];
  const { t } = useTranslation('pages');

  // Handelrs
  const handleGetLesson = async () => {
    setLoading(true);
    try {
      const res = await lessonService.getLesson(courseId!, moduleId!, lessonId!);
      if (!res) throw new Error('Lesson not found!');
      setLesson(res);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (_err: any) {
      const { code, responseMessage }: APIError = _err;
      if (code === 'STUDENT_NOT_ENROLLED')
        return navigate(routes.checkout.course.path.replace(':courseId', courseId!));
      setError(responseMessage);
    }
    setLoading(false);
  };

  const handleSaveUserData = async (
    blockId: string,
    checkList: CheckboxItem[],
    allChecked = false
  ) => {
    const checkboxSectionUserData: CheckboxSectionUserData = {
      blockId,
      allChecked,
      courseId,
      moduleId,
      lessonId,
      checkList: checkList.map((item) => ({ id: item.id, checked: item.checked })),
    };
    setLoading(true);
    try {
      await userService.saveLessonData({
        lessonId: lessonId!,
        data: checkboxSectionUserData,
      });

      if (!checkboxSectionUserData.allChecked) return handleGetLesson();

      // The redirection to the home page is not anymore needed
      /* navigate(
        `${routes.course.modules.basePath?.replace(':courseId', courseId!)}${
          routes.course.modules.path
        }`
      );*/

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (_err: any) {
      const err: APIError = _err;
      setError(err.responseMessage);
    }
    setLoading(false);
  };

  // Effects
  useEffect(() => {
    handleGetLesson();
    const checkHasNextLesson = async () => {
      const result = await lessonService.hasNextLesson(lessonId!);
      setHasNextLesson(result);
    };

    checkHasNextLesson();
  }, []);

  const toLessons = () => {
    navigate(
      `${routes.course.modules.basePath?.replace(':courseId', courseId!)}${
        routes.course.modules.path
      }#lessons`
    );
  };

  const toPreviousLesson = async () => {
    const lesson = await lessonService.getPreviousLesson(lessonId!);
    if (!lesson) throw new Error('Lesson not found!');
    const previousLessonURL = `${routes.course.lesson.path
      .replace(':courseId', courseId!)
      .replace(':moduleId', lesson.moduleId)
      .replace(':lessonId', lesson.id)}`;
    window.location.href = previousLessonURL;
  };

  const toNextLesson = async () => {
    const lesson = await lessonService.getNextLesson(lessonId!);
    if (!lesson) throw new Error('Lesson not found!');
    const nextLessonURL = `${routes.course.lesson.path
      .replace(':courseId', courseId!)
      .replace(':moduleId', lesson.moduleId)
      .replace(':lessonId', lesson.id)}`;
    window.location.href = nextLessonURL;
  };

  // Renderers
  const renderBlocks = () => {
    if (!lesson?.buildingBlocks?.length) return null;

    const { buildingBlocks } = lesson;
    return buildingBlocks.map((block, index) => {
      const Block = BUILDING_BLOCKS[block.type];

      if (block.type === LESSON_BLOCK_TYPE.LESSON_HERO) {
        return <Block id={block.id} key={block.id} data={block.data} coverHeader={index === 0} />;
      }

      if (block.type === LESSON_BLOCK_TYPE.CHECKBOX_FORM) {
        return (
          <CheckboxForm
            id={block.id}
            key={block.id}
            data={block.data}
            handleSaveUserData={handleSaveUserData}
            userData={userLessonDataList.find((item) => item.data?.blockId === block.id)}
          />
        );
      }

      return <Block id={block.id} key={block.id} data={block.data} />;
    });
  };

  return (
    <div className="relative bg-white">
      <AbosluteSpinner show={loading} />
      <Alert message={error} />
      {renderBlocks()}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '50px',
        }}
      >
        <div style={{ margin: '0 100px' }}></div>
        <Button
          background="green"
          color="white"
          title={t('lesson.previous') || ''}
          size="xl"
          round="2xl"
          className="mb-5"
          full={true}
          onClick={toPreviousLesson}
          disabled={lesson?.order === 1}
        />
        <div style={{ margin: '0 100px' }}></div>
        <Button
          background="green"
          color="white"
          title={t('lesson.backToLessons') || ''}
          size="xl"
          round="2xl"
          className="mb-5"
          full={true}
          onClick={toLessons}
        />
        <div style={{ margin: '0 100px' }}></div>
        <Button
          background="green"
          color="white"
          title={t('lesson.next') || ''}
          size="xl"
          round="2xl"
          className="mb-5"
          full={true}
          onClick={toNextLesson}
          disabled={!hasNextLesson}
        />
        <div style={{ margin: '0 100px' }}></div>
      </div>
    </div>
  );
};
export default LessonPage;
