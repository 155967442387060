import { ReactElement, useState, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Link, NavLink } from 'react-router-dom';

// Import services
import authService from 'services/auth.service';

import { Button } from 'components/Button/index';
import { Image } from 'components/Image';
import { Backdrop } from 'components/Backdrop';

// Import recoil atoms
import { userAtom } from 'store/userAtom';
import { userAuth } from 'store/auth';
import { useTranslation } from 'react-i18next';
import ChangeLanguage from 'containers/ChangeLanguage';
interface Props {
  className?: string;
}

const Navigation = ({ className }: Props): ReactElement => {
  // translation
  const { t } = useTranslation();

  // context
  const setUserAuth = useSetRecoilState(userAuth);
  const setUser = useSetRecoilState(userAtom);

  // initiate state
  const { user } = useRecoilValue(userAtom);
  const dropdownRef = useRef<HTMLButtonElement>(null);
  const dropdownRefOurServices = useRef<HTMLButtonElement>(null);
  const [dropdown, setDropdown] = useState(false);
  const [dropdownOurServices, setDropdownOurServices] = useState(false);
  const [showHamburger, setShowHamburger] = useState(false);
  // Handlers
  const handleSignOut = () => {
    setUserAuth({ isAuthenticated: false });
    setUser({ user: null });
    authService.signOut();
  };

  const toggleDropdown = (): void => {
    setDropdown(!dropdown);
    setTimeout(() => {
      dropdownRef?.current?.focus();
    }, 150);
  };

  const toggleDropdownOurServices = (): void => {
    setDropdownOurServices(!dropdownOurServices);
    setTimeout(() => {
      dropdownRefOurServices?.current?.focus();
    }, 150);
  };

  const closeDrpodown = (): void => {
    setTimeout(() => setDropdown(false), 150);
  };
  const closeDrpodownOurServices = (): void => {
    setTimeout(() => setDropdownOurServices(false), 150);
  };

  const hamburgerClases = showHamburger
    ? 'flex w-10/12 opacity-100 flex-col'
    : 'w-0  opacity-0 xl:opacity-100';

  const imageZIndex = showHamburger ? 'z-50' : 'z-30';

  const handleAppButtonCallToAction = () => {
    window.location.replace('https://madetogrow.no/app/');
  };

  return (
    <>
      <div
        className={`fixed top-0 left-0 right-0 ${imageZIndex} flex w-full  flex-1 flex-col p-0  ${
          className ? className : ''
        }`}
      >
        <div className="relative h-28">
          <img
            src="/assets/img/background-navigation.svg"
            className="top-0 left-0 right-0 m-0 h-28 w-full object-cover p-0"
            alt="Bg Navigation"
          />
        </div>
      </div>
      <div
        className={`relative top-0 z-50 flex w-full flex-1 flex-col  ${className ? className : ''}`}
      >
        <nav
          style={{ backgroundColor: 'rgba(0,0,0,0.4)' }}
          className="fixed z-50 flex h-28 w-full justify-between bg-transparent px-4 backdrop-blur-md backdrop-filter"
        >
          {/* <!-- top bar left --> */}

          <ul className="flex items-center">
            <li className="">
              <NavLink
                className={({ isActive }) =>
                  `hover:shadow-md ${isActive ? 'pointer-events-none' : ''}`
                }
                to="/"
                rel="noreferrer"
              >
                <img className="h-auto w-40 object-fill md:w-64" alt="logo" src="/mtg-logo.png" />
              </NavLink>
            </li>
          </ul>

          <div className="self-center xl:hidden">
            <button
              onClick={() => setShowHamburger(!showHamburger)}
              type="button"
              className="text-secondary-color hover:text-primary-color focus:outline-none inline-flex items-center justify-center rounded-md bg-white p-2 hover:bg-gray-100 focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              aria-expanded="false"
            >
              <span className="sr-only">{t('global:openMenu')}</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>

          <div
            className={`
              ${hamburgerClases}
              absolute right-0  top-0  h-screen items-end  justify-between  transition-all duration-150 xl:visible
              xl:relative xl:left-auto xl:top-auto xl:flex xl:h-auto xl:w-fit xl:flex-row xl:bg-transparent
            `}
          >
            <div className="absolute z-40 h-screen w-full overflow-hidden bg-blue-nav-bg xl:hidden">
              <Image alt="mobile img" src="/assets/img/image-hamburger-mobile.png" />
            </div>
            <div className="w-full">
              <div className="relative z-50 mt-10 flex w-full items-center justify-between px-5 xl:hidden">
                <h1 className="text-2xl font-medium text-white">{t('global:menu')}</h1>
                <button
                  onClick={() => setShowHamburger(!showHamburger)}
                  type="button"
                  className="text-secondary-color hover:text-primary-color focus:outline-none inline-flex items-center justify-center rounded-md bg-transparent p-2 text-white focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  aria-expanded="false"
                >
                  <span className="sr-only">{t('global:openMenu')}</span>
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              </div>
              <ul className="relative z-50 flex flex-col items-end p-5 text-right xl:h-28 xl:flex-row xl:items-center xl:p-0">
                <li className="order-3 mt-5 text-base font-medium text-white xl:order-none xl:mt-0 xl:pr-6">
                  <a
                    className="hover:shadow-md"
                    href="https://madetogrow.no/om-oss/"
                    rel="noreferrer"
                  >
                    {t('aboutUs')}
                  </a>
                </li>
                <li
                  className="order-9 mt-5 text-base font-medium text-white xl:order-none xl:mt-0 xl:pr-6"
                  onMouseEnter={toggleDropdownOurServices}
                  onMouseLeave={closeDrpodownOurServices}
                >
                  <div className="dropdown inline-block">
                    <Button
                      onClick={toggleDropdownOurServices}
                      onBlur={closeDrpodownOurServices}
                      className="order-9 text-base font-medium text-white"
                      size="none"
                      disabledRing={true}
                    >
                      {t('services')}
                      <svg
                        className="h-7 w-7 fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{' '}
                      </svg>
                    </Button>

                    <ul
                      className={`dropdown-menu
                        absolute flex-col text-gray-700
                        ${dropdownOurServices ? 'flex' : 'hidden'}
                      `}
                    >
                      <li className="w-full overflow-hidden bg-gray-200">
                        <a
                          className="whitespace-no-wrap block w-full cursor-pointer px-4 py-2 text-left font-medium text-black-100 hover:bg-gray-400"
                          href="https://madetogrow.no/ledere/"
                          rel="noreferrer"
                        >
                          {t('employersAndManagers')}
                        </a>
                      </li>
                      <li className="w-full overflow-hidden bg-gray-200">
                        <a
                          className="whitespace-no-wrap block w-full cursor-pointer px-4 py-2 text-left font-medium text-black-100 hover:bg-gray-400"
                          href="https://madetogrow.no/skoleledere/"
                          rel="noreferrer"
                        >
                          {t('schoolLeadersAndTeachers')}
                        </a>
                      </li>
                      <li className="w-full overflow-hidden bg-gray-200">
                        <a
                          className="whitespace-no-wrap block w-full cursor-pointer px-4 py-2 text-left font-medium text-black-100 hover:bg-gray-400"
                          href="https://madetogrow.no/samfunnet/"
                          rel="noreferrer"
                        >
                          {t('society')}
                        </a>
                      </li>
                      <li className="w-full overflow-hidden bg-gray-200">
                        <a
                          className="whitespace-no-wrap block w-full cursor-pointer px-4 py-2 text-left font-medium text-black-100 hover:bg-gray-400"
                          href="https://madetogrow.no/coaching/"
                          rel="noreferrer"
                        >
                          {t('coachingAndMentalTraining')}
                        </a>
                      </li>
                      <li className="w-full overflow-hidden bg-gray-200">
                        <a
                          className="whitespace-no-wrap block w-full cursor-pointer px-4 py-2 text-left font-medium text-black-100 hover:bg-gray-400"
                          href="https://madetogrow.no/foredrag/"
                          rel="noreferrer"
                        >
                          {t('lecture')}
                        </a>
                      </li>
                      <li className="w-full overflow-hidden bg-gray-200">
                        <a
                          className="whitespace-no-wrap block w-full cursor-pointer px-4 py-2 text-left font-medium text-black-100 hover:bg-gray-400"
                          href="https://madetogrow.no/samsara/"
                          rel="noreferrer"
                        >
                          {t('magicalVibesWithSamsara')}
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="order-3 mt-5 text-base font-medium text-white xl:order-none xl:mt-0 xl:pr-6">
                  <a
                    className="hover:shadow-md"
                    href="https://madetogrow.no/blogg/"
                    rel="noreferrer"
                  >
                    {t('blog')}
                  </a>
                </li>
                <li className="order-3 mt-5 text-base font-medium text-white xl:order-none xl:mt-0 xl:pr-6">
                  <a
                    className="hover:shadow-md"
                    href="https://madetogrow.no/kontakt/"
                    rel="noreferrer"
                  >
                    {t('contactUs')}
                  </a>
                </li>
                <li className="order-3 mt-5 text-base font-medium text-white xl:order-none xl:mt-0 xl:pr-6">
                  <Button
                    size="sm"
                    round="4xl"
                    className="flex items-center font-semibold text-white ring-2 ring-offset-2"
                    onClick={handleAppButtonCallToAction}
                  >
                    {t('mtgApp')}
                  </Button>
                </li>
                <li className="order-3 mt-5 text-base font-medium text-white xl:order-none xl:mt-0 xl:pr-6">
                  <Link to="/" className="text-base text-white">
                    <Button
                      size="sm"
                      round="4xl"
                      className="flex items-center font-semibold text-white ring-2 ring-offset-2"
                    >
                      {t('mtgUniversity')}
                    </Button>
                  </Link>
                </li>
                {/* <NavItem
                  className="flex flex-row-reverse justify-between order-1 pb-3 text-white border-b border-white xl:order-none xl:w-auto xl:justify-center xl:border-0 xl:pb-0 xl:pr-6"
                  icon={faSearch}
                  to="#"
                >
                  <h6 className="text-xl text-white xl:hidden">Search made to grow</h6>
                </NavItem>*/}
                <li className="order-3 mt-5 cursor-pointer text-base font-medium text-white xl:order-none xl:mt-0 xl:pr-6">
                  <ChangeLanguage />
                </li>

                <li className="relative mt-5 hidden text-base font-medium text-white xl:mt-0 xl:flex xl:pr-6">
                  <div className="">
                    <div className="dropdown relative inline-block">
                      <Button
                        onClick={toggleDropdown}
                        onBlur={closeDrpodown}
                        size="sm"
                        round="4xl"
                        className="flex items-center bg-white font-semibold text-gray-secondary"
                      >
                        {user?.profile?.firstName && (
                          <span className="mr-1 pt-1 text-base">{user?.profile?.firstName}</span>
                        )}
                        {user?.profile?.lastName && (
                          <span className="pt-1 text-base">{user?.profile?.lastName}</span>
                        )}
                        {!user?.profile && <span className="pt-1 text-base">{user?.email}</span>}
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="user-circle"
                          className="svg-inline--fa fa-user-circle fa-w-22 mx-2 h-6 w-8 "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 496 512"
                        >
                          <path
                            fill="#A8D060"
                            d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"
                          ></path>
                        </svg>
                        <svg
                          className="h-7 w-7 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{' '}
                        </svg>
                      </Button>

                      <ul
                        className={`dropdown-menu
                      absolute w-full flex-col px-2 pt-1 text-gray-700
                      ${dropdown ? 'flex' : 'hidden'}
                    `}
                      >
                        <li className="w-full overflow-hidden rounded bg-gray-200">
                          <Link
                            to="/settings"
                            type="button"
                            className="whitespace-no-wrap block w-full cursor-pointer px-4 py-2 text-center font-medium text-black-100 hover:bg-gray-400"
                          >
                            {t('settings')}
                          </Link>
                          <button
                            onClick={handleSignOut}
                            ref={dropdownRef}
                            type="button"
                            className="whitespace-no-wrap block w-full cursor-pointer px-4 py-2 font-medium text-black-100 hover:bg-gray-400"
                          >
                            {t('logout')}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="relative z-50 w-full px-5 pb-10 text-center xl:hidden">
              <div className="flex flex-col text-center">
                <div className="">
                  <div className="dropdown relative inline-block">
                    <Button
                      onClick={toggleDropdown}
                      size="xl"
                      round="4xl"
                      className={`flex flex-col items-center font-semibold ${
                        showHamburger ? 'text-white' : 'text-gray-secondary'
                      }`}
                    >
                      {user?.profile?.firstName && (
                        <span className="mr-1 pt-1 text-base">{user?.profile?.firstName}</span>
                      )}
                      {user?.profile?.lastName && (
                        <span className="pt-1 text-base">{user?.profile?.lastName}</span>
                      )}
                      {!user?.profile && <span className="pt-1 text-base">{user?.email}</span>}
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="user-circle"
                        className="svg-inline--fa fa-user-circle fa-w-22 mx-2 h-7 w-7 "
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 496 512"
                      >
                        <path
                          fill="#A8D060"
                          d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"
                        ></path>
                      </svg>
                    </Button>

                    <ul
                      className={`dropdown-menu
                        absolute w-full flex-col px-2 pt-1 text-gray-700
                        ${dropdown ? 'flex' : 'hidden'}
                      `}
                    >
                      <li className="w-full">
                        <button
                          onMouseDown={handleSignOut}
                          ref={dropdownRef}
                          type="button"
                          onBlur={closeDrpodown}
                          className="whitespace-no-wrap block w-full cursor-pointer rounded-t rounded-b bg-gray-200 px-4 py-2 hover:bg-gray-400"
                        >
                          {t('common:logout')}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <hr className="my-5" />
              <Link to="/settings/" className="text-base text-white">
                {t('settings')}
              </Link>
            </div>
          </div>
        </nav>
      </div>
      <Backdrop
        show={showHamburger}
        className="z-0 xl:hidden"
        portalContainerId="main-layout__portal-container"
        onClick={() => {
          setShowHamburger(false);
        }}
      />
    </>
  );
};
export default Navigation;
