import { ReactElement } from 'react';

// Types
import { LessonBlock, LESSON_BLOCK_TYPE } from 'common/types/BuildingBlock';
// Pages
import { BUILDING_BLOCKS } from 'pages/Lesson/LessonPage';

// Import components
// import CourseFooterContent from 'components/Courses/CourseFooterContent/CourseFooterContent';
// import CourseList from 'components/Courses/CourseList/CourseList';
// import CourseGallery from 'components/Courses/CourseGallery/CourseGallery';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  overviewBlocks: any;
}

export default function CourseOverview({ overviewBlocks }: Props): ReactElement {
  // Renderers
  const renderBlocks = () => {
    if (!overviewBlocks?.length) return null;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return overviewBlocks.map((block: LessonBlock<any>, index: number) => {
      const Block = BUILDING_BLOCKS[block.type];

      if (block.type === LESSON_BLOCK_TYPE.LESSON_HERO) {
        return <Block id={block.id} key={block.id} data={block.data} coverHeader={index === 0} />;
      }
      return <Block id={block.id} key={block.id} data={block.data} />;
    });
  };

  return (
    <div className="bg-white">
      {renderBlocks()}
      {/* <CourseList />
      <CourseGallery />
      <CourseFooterContent /> */}
    </div>
  );
}
