import { useRef, useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getInnerHeight = (element: any): number => {
  const computed = getComputedStyle(element);
  const padding = parseInt(computed.paddingLeft) + parseInt(computed.paddingRight);

  return element.clientHeight - padding;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getInnerWidth = (element: any): number => {
  const computed = getComputedStyle(element);
  const padding = parseInt(computed.paddingTop) + parseInt(computed.paddingBottom);

  return element.clientWidth - padding;
};

export const useInnerSize = <T>() => {
  // Ref
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const elementRef = useRef<T>(null);

  // State
  const [innerHeight, setInnerHeight] = useState<number>(0);
  const [innerWidth, setInnerWidht] = useState<number>(0);

  // Handlers
  const updateInnerSize = () => {
    setInnerHeight(getInnerHeight(elementRef.current));
    setInnerWidht(getInnerWidth(elementRef.current));
  };

  useEffect(() => {
    window.addEventListener('resize', updateInnerSize);
    // Removes listener on unmount
    return () => {
      window.removeEventListener('resize', updateInnerSize);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(updateInnerSize, 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    updateInnerSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef.current]);
  return { elementRef, innerWidth, innerHeight, updateInnerSize };
};
