import { ReactElement } from 'react';

// Import Component
import { Button } from 'components/Button';
import Header from 'components/Header/Header';
import { Image } from 'components/Image';
import Paragraph from 'components/Paragraph/Paragraph';
import SectionDivider from 'components/SectionDivider/SectionDivider';
import { useTranslation } from 'react-i18next';

const Welcome = (): ReactElement => {
  const { t } = useTranslation('pages');
  return (
    <div className="z-50 h-full w-full bg-gray-400">
      <Image
        className="absolute top-0 left-0 right-0 z-10"
        alt="bg-img"
        src="/assets/img/course-header.jpg"
      />
      <div className="relative z-20 mx-20 flex flex-col items-center py-10 lg:mx-72">
        <img src="/mtg-logo.png" className="" alt="MTG Logo" />
        <div className="relative my-10">
          <img
            className="absolute top-0 left-0 z-20 -translate-x-1/2 -translate-y-1/3 scale-50 transform object-cover md:-translate-x-1/2 md:-translate-y-1/2 lg:scale-100"
            src="/assets/img/leaf-topleft.svg"
            alt="Checkout Background"
          />
          <img
            className="absolute top-0 right-0 z-50 translate-x-1/2 translate-y-1/2 scale-50 transform object-cover md:translate-x-1/2 lg:scale-75"
            src="/assets/img/leaf-topright.svg"
            alt="Checkout Background"
          />
          <img
            className="absolute bottom-0 left-0 z-20 -translate-x-10 translate-y-1/2 scale-50 transform object-cover lg:scale-100"
            src="/assets/img/leaf-botleft.svg"
            alt="Checkout Background"
          />
          <div className="relative z-40 overflow-hidden rounded-4xl bg-white">
            <div className="relative">
              <Image src="/assets/img/background-lesson.jpg" alt="welcome" />
              <div className="absolute left-0 right-0 -bottom-1 z-20 flex h-fit w-full">
                <SectionDivider className="w-full" />
              </div>
            </div>
            <div className="px-8 py-10 md:px-20">
              <Header className="text-center font-bold" title={t('welcome.header')} />
              <Paragraph className="my-7 text-center text-gray-500" text={t('welcome.paragraph')} />
              <Button
                size="2xl"
                round="4xl"
                background="green"
                color="white"
                title={t('welcome.CTA')!}
                className="mx-auto text-center"
              />
            </div>
          </div>
        </div>
        <div>
          <p>{t('welcome.footerText')}</p>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
