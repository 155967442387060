import { Lesson } from 'common/types/Lesson.type';

export const lessonCheckListDone = (lesson: Lesson): boolean => {
  if (!lesson?.userLessonDataList?.length) return false;
  return (
    lesson?.userLessonDataList?.every((userLessonData) => userLessonData.data.allChecked) || false
  );
};

export const allLessonsChecklistDone = (lessons?: Lesson[]): boolean => {
  if (!lessons?.length) return false;
  return lessons.every(lessonCheckListDone);
};

export const lessonsHaveUserLessonData = (lessons?: Lesson[]): boolean => {
  if (!lessons?.length) return false;
  return lessons.some((lesson) => lesson.userLessonDataList?.length);
};
