import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

// Import Components
import SubHeader from 'components/SubHeader/SubHeader';
import Paragraph from 'components/Paragraph/Paragraph';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
}

const CertificatesPage = ({ className = '' }: Props): ReactElement => {
  const { t } = useTranslation('pages');
  return (
    <div className={className}>
      <div className="rounded-2xl.5 bg-white bg-transparent p-5 shadow lg:bg-white lg:p-7 lg:shadow-none">
        <div className="flex flex-col items-center justify-between lg:mb-3 lg:flex-row">
          <SubHeader
            title={t('settings.certificatesPage.title')!}
            className="text-2xl.5 md:text-3xl"
          />
          <div className="my-3 flex  lg:my-0">
            <span className="mr-2 font-medium text-primary">
              {t('settings.certificatesPage.completeOn')}
            </span>
            <span className="text-gray-700">Dec 24th 2021</span>
          </div>
        </div>
        <Paragraph className="text-gray-700" text={t('settings.certificatesPage.paragraph')} />
        <hr className="my-5" />
        <div className="flex flex-col items-center lg:flex-row lg:justify-between">
          <Link className="text-2xl.5 font-medium text-blue-300" to="#">
            {t('settings.certificatesPage.viewCertificate')}
          </Link>
          <hr className="my-1 lg:hidden" />
          <Link className="text-2xl.5 font-medium text-primary" to="#">
            {t('settings.certificatesPage.download')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CertificatesPage;
