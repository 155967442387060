import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';

import { Link, useLocation } from 'react-router-dom';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// Import types
import { AuthRequest } from 'common/types/Auth.type';

// Import services
import authService from 'services/auth.service';

// Import components
import { Input } from 'components/Form/Input';
import { Alert, AlertType } from 'components/Alert';
import { Checkbox } from 'components/Form/Checkbox';
import { Button } from 'components/Button/index';

// Import partials
import SignupLoginLinks from '../partials/_SignupLoginLinks';

// Import state
import { userAuth } from 'store/auth';
import { userAtom } from 'store/userAtom';
import { useTranslation } from 'react-i18next';

const formSchema = yup.object().shape({
  email: yup.string().email('Vennligst skriv inn en gyldig e-postadresse').required('Epost kreves'),
  password: yup
    .string()
    .required('Passord kreves!')
    .min(6, 'Passordet må være på minst 6 tegn!')
    .max(20, 'Passordet må være mindre enn 20 tegn!'),
  rememberMe: yup.bool(),
});

const Signin: FC = () => {
  const { t } = useTranslation('pages');
  // State
  const setUserAuth = useSetRecoilState(userAuth);
  const setUser = useSetRecoilState(userAtom);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const location = useLocation();

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const passwordChanged = query.get('passwordChanged');

  let warningResetPassword = '';
  if (passwordChanged)
    warningResetPassword =
      'Tilbakestilling av ditt passord var vellykket. Du vil motta en epost med ditt nye passord.';

  // FOrm
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<AuthRequest>({ resolver: yupResolver(formSchema) });

  const rememberMe = watch('rememberMe');

  // Handlers
  const onSubmit = async (data: AuthRequest) => {
    setLoading(true);
    setError('');
    authService
      .signIn(data)
      .then((response) => {
        setUserAuth({
          isAuthenticated: true,
        });
        setUser({ user: authService.getUser() });
      })
      .catch((error) => {
        if (parseInt(`${error.code}`) === 401) return setError('Feil epost eller passord.');
        setError(error.responseMessage);
      });
    setLoading(false);
  };

  return (
    <div className="relative">
      <img src="/mtg-logo.png" className="mx-auto" alt="MTG Logo" />
      <div className="z-10 mx-auto my-5 w-11/12 rounded-4xl bg-white bg-opacity-75 bg-cover px-8 py-10 shadow backdrop-blur backdrop-filter md:mx-auto md:w-460">
        <p
          aria-label={t('signin.title')!}
          className="mb-5 text-center text-2xl font-bold leading-6 text-gray-800 "
        >
          {t('signin.title')}
        </p>
        <div className="my-5 flex w-full items-center justify-between py-5"></div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Alert type={AlertType.INFO} message={warningResetPassword} />
          <Alert message={error} />
          <div className="w-full ">
            <Input
              autoComplete="email"
              type="email"
              {...register('email')}
              placeholder={t('signin.email')}
              errors={errors}
            />
          </div>
          <div className="mt-4 w-full">
            <Input
              autoComplete="password"
              type="password"
              placeholder={t('signin.password')}
              {...register('password')}
              errors={errors}
            />
          </div>
          <div className="mt-5">
            <Checkbox
              checked={rememberMe}
              {...register('rememberMe')}
              label={t('signin.rememberMe')}
            />
          </div>
          <div className="mt-5">
            <Button
              disabled={loading}
              background="green"
              color="white"
              type="submit"
              title={t('signin.signin')!}
              full={true}
              round="4xl"
              size="xl"
            />
          </div>
          <div className="my-5 ">
            <Link to="/reset-password" className="text-center text-lg font-bold text-secondary">
              {t('signin.forgotPassword')}?
              {location.pathname === '/reset-password' && (
                <div className="absolute bottom-0 h-1 w-full rounded-md bg-white"></div>
              )}
            </Link>
          </div>
          <hr className="h-2 w-full border-primary " />
          <p className="mt-4 text-center text-lg font-normal leading-none text-gray-secondary">
            {t('signin.notAMember')}&nbsp;
            <span
              tabIndex={0}
              role="link"
              aria-label="Sign up"
              className="cursor-pointer text-lg font-normal leading-none text-secondary"
            >
              <Link className="text-center text-lg font-bold text-secondary" to="/auth/sign-up">
                {t('signin.register')}
              </Link>
            </span>
          </p>
        </form>
      </div>
      <SignupLoginLinks />
    </div>
  );
};

export default Signin;
