import React, { ReactElement } from 'react';
// Import component
import Header from 'components/Header/Header';
import SubHeader from 'components/SubHeader/SubHeader';
import { Image } from 'components/Image';
// Import Type
import { GalleryData } from 'common/types/RenderEngine.type';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
export interface GalleryProp {
  data: GalleryData;
}
export default function Gallery({
  data: { header, subHeader, media },
}: GalleryProp): ReactElement<GalleryProp> {
  const renderMedia = () => {
    if (!media?.length) return null;
    return media.map(({ type, image, video }, index) => (
      <div
        className="relatvie overflow-hidden rounded-xl "
        key={`gallery_${type}_${image?.path || video?.path}_${index}`}
      >
        {type === 'VideoPlayerType' && video ? (
          <div className="relatvive aspect-w-4 aspect-h-3 overflow-hidden">
            <VideoPlayer className="w-full rounded-xl object-cover" data={{ ...video }} />
          </div>
        ) : null}

        {type === 'ImageType' && image ? (
          <div className="relatvive aspect-w-4 aspect-h-3 overflow-hidden rounded-xl">
            <Image src={image.path} alt={image.title || ''} zoomable />
          </div>
        ) : null}
      </div>
    ));
  };
  return (
    <div className="w-full px-4 py-10 text-center lg:py-20 lg:px-36">
      {subHeader && <SubHeader className="mb-5 text-center" title={subHeader} />}
      {header && <Header className="mb-20 text-center" title={header} />}
      {media?.length ? (
        <div className="relative mx-auto grid w-full grid-cols-2 gap-4 lg:grid-cols-3 lg:gap-16">
          {renderMedia()}
        </div>
      ) : null}
    </div>
  );
}
