import { ReactElement, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Import config
import routes from 'config/routesConfig';

// Import uitls
import { allLessonsChecklistDone, lessonsHaveUserLessonData } from 'utils/lesson.util';

// Import component
import Header from 'components/Header/Header';
import SubHeader from 'components/SubHeader/SubHeader';

// Import Icon
import { ReactComponent as ArrowLeftIcon } from 'assets/svg-icons/arrow-left-icon.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/svg-icons/arrow-down-icon.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/svg-icons/arrow-right-icon.svg';
import { ReactComponent as CompletedIcon } from 'assets/svg-icons/completed-icon.svg';
import { ReactComponent as InProgressIcon } from 'assets/svg-icons/in-progress.svg';
import { ReactComponent as LockedVideoIcon } from 'assets/svg-icons/locked-video.svg';
import { ReactComponent as PlayVideoIcon } from 'assets/svg-icons/play-video.svg';

// Types
import { Module } from 'common/types/Module.type';
import { Lesson } from 'common/types/Lesson.type';

// Import components
import { AbosluteSpinner } from 'components/Spinners';
export interface Props {
  modules: Module[];
  loading: boolean;
  courseId: string;
  studentIsEnrolled: boolean;
}

enum ModuleStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  LOCKED = 'LOCKED',
}

export default function CourseModule({
  modules,
  loading,
  courseId,
  studentIsEnrolled,
}: Props): ReactElement {
  const [dropdown, setDropdown] = useState('');

  // Handlers
  const getStatus = (lessons: Lesson[], moduleIsLocked = false): ModuleStatus => {
    if (moduleIsLocked) return ModuleStatus.LOCKED;
    const lessonIsDone = allLessonsChecklistDone(lessons);
    let status: ModuleStatus | null = null;
    if (lessonIsDone && !status) status = ModuleStatus.COMPLETED;
    if (!status && lessonsHaveUserLessonData(lessons)) status = ModuleStatus.IN_PROGRESS;
    if (!status) status = ModuleStatus.NEW;

    return status!;
  };
  const getIcon = (status: ModuleStatus) => {
    switch (status) {
      case ModuleStatus.NEW:
        return <PlayVideoIcon className="h-10 w-10 fill-current lg:h-10 lg:w-20" />;
      case ModuleStatus.IN_PROGRESS:
        return <InProgressIcon className="h-10 w-10 fill-current lg:h-10 lg:w-20" />;
      case ModuleStatus.COMPLETED:
        return <CompletedIcon className="h-10 w-10 fill-current lg:h-10 lg:w-20" />;
      case ModuleStatus.LOCKED:
        return <LockedVideoIcon className="h-10 w-10 fill-current lg:h-10 lg:w-20" />;
      default:
        return <PlayVideoIcon className="h-10 w-10 fill-current lg:h-10 lg:w-20" />;
    }
  };
  const handleToggleDropdown = (moduleId: string) => {
    if (dropdown === moduleId) return setDropdown('');
    setDropdown(moduleId);
  };

  // Effects
  // Set active dropdown item
  useEffect(() => {
    if (!dropdown) {
      const firstNotCompleted = modules.find((_module) => {
        if (!_module.lessons?.length) return false;
        const moduleStatus = getStatus(_module.lessons);
        if (moduleStatus !== ModuleStatus.COMPLETED) return true;
        return false;
      });
      setDropdown(firstNotCompleted?.id || '');
    }
  }, [modules, dropdown]);

  // Renderers
  const renderModuleLessons = (
    lessons: Lesson[] = [],
    moduleId: string,
    showLessons = false,
    moduleIsLocked = false
  ) => {
    if (!showLessons) return null;
    if (!lessons.length) return null;
    const items = lessons.map((lesson) => {
      const status = getStatus([lesson], moduleIsLocked);
      const icon = getIcon(status);
      let route = routes.course.lesson.path
        .replace(':courseId', courseId)
        .replace(':moduleId', moduleId)
        .replace(':lessonId', lesson.id);

      if (moduleIsLocked) route = routes.checkout.course.path.replace(':courseId', courseId);
      return (
        <Link
          to={route}
          key={lesson.id}
          className="flex flex-nowrap justify-between border-b-2 border-gray-200 py-5"
        >
          <div className="flex items-center">
            <ArrowRightIcon />
            <div className="ml-10 flex flex-wrap items-center text-white">
              <SubHeader
                fontWeight="font-medium"
                className="mr-2 text-xl text-gray-500 md:text-3xl"
                title={lesson.title}
              />
              <SubHeader
                fontWeight="font-normal"
                className="mt-1 text-base text-gray-500 md:text-lg"
                title={`${lesson.estimatedTime} min`}
              />
            </div>
          </div>
          <div className="flex items-center">{icon}</div>
        </Link>
      );
    });

    return (
      <div className="flex flex-col flex-nowrap justify-between rounded-bl-xl rounded-br-xl bg-white px-7 pb-5">
        {items}
      </div>
    );
  };

  const renderModules = () =>
    modules.map(({ id: _moduleId, title, lessons, course, preparationModule }) => {
      const moduleIsChargable = !preparationModule;
      const moduleIsLocked = !studentIsEnrolled && moduleIsChargable;
      const status = getStatus(lessons || [], moduleIsLocked);
      const icon = getIcon(status);

      return (
        <div id={_moduleId} key={_moduleId} className="mb-2 w-full">
          <div
            className={`flex cursor-pointer flex-nowrap items-center justify-between bg-primary p-7
            ${dropdown === _moduleId ? 'rounded-tl-xl rounded-tr-xl' : 'rounded-xl'}
          `}
            onClick={() => handleToggleDropdown(_moduleId)}
          >
            <Header isWhite title={title} />
            <div className="flex flex-nowrap items-center">
              {icon}
              <Header className="mx-5" title="|" isWhite />
              {_moduleId !== dropdown ? <ArrowLeftIcon /> : <ArrowDownIcon />}
            </div>
          </div>
          {renderModuleLessons(lessons, _moduleId, _moduleId === dropdown, moduleIsLocked)}
        </div>
      );
    });

  return (
    <div className="relative flex w-full flex-col px-4 py-20 lg:px-36">
      <AbosluteSpinner show={loading} />
      {renderModules()}
    </div>
  );
}
