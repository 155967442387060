import { ReactElement, useState, useEffect } from 'react';

// Import Component
import Header from 'components/Header/Header';
import SubHeader from 'components/SubHeader/SubHeader';
import { Checkbox } from 'components/Form/Checkbox';
import { Button } from 'components/Button';

// Import Type
import { CheckboxData, CheckboxItem } from 'common/types/RenderEngine.type';
import { UserCheckboxSectionData } from 'common/types/UserLessonData.type';
import { useTranslation } from 'react-i18next';

export interface Props {
  id: string;
  data: CheckboxData;
  userData?: UserCheckboxSectionData;
  disabled?: boolean;
  handleSaveUserData?: (blockId: string, checkList: CheckboxItem[], allChecked?: boolean) => void;
}

export default function CheckboxForm({
  id,
  data: { header, subHeader, list },
  userData,
  handleSaveUserData,
  disabled,
}: Props): ReactElement {
  const userChecklist = userData?.data.checkList || [];
  const [checklist, setChecklist] = useState<CheckboxItem[]>(
    list?.map((item) => {
      const userItem = userChecklist.find((userItem) => userItem.id === item.id);
      return { ...item, checked: userItem?.checked || false };
    }) || []
  );
  const [allChecked, setAllChecked] = useState<boolean>(false);

  // Handlers
  const handleOnToggle = (index: number) => {
    const listItem = { ...checklist[index] };
    listItem.checked = !listItem.checked;

    const newState = [...checklist];
    newState[index] = listItem;

    setChecklist(newState);
  };

  const handleOnSubmit = () => {
    handleSaveUserData &&
      handleSaveUserData(
        id,
        checklist,
        checklist.every((item) => item.checked)
      );
  };

  // Effects
  useEffect(() => {
    setAllChecked(checklist.every((item) => item.checked));
  }, [checklist]);

  // Renderes
  const renderChecklist = (): ReactElement[] => {
    return checklist.map(({ checked, label, id }: CheckboxItem, index) => {
      return (
        <Checkbox
          key={id}
          checked={checked}
          onChange={() => handleOnToggle(index)}
          label={label || ''}
          className="mb-5"
          name={`${id}`}
          disabled={disabled}
        />
      );
    });
  };

  const { t } = useTranslation();
  return (
    <div className="mx-auto w-11/12 py-20 text-center lg:w-10/12 xl:w-8/12">
      {subHeader && <SubHeader className="mb-5" title={subHeader} />}
      {header && <Header title={header} />}
      {checklist?.length ? <div className="mt-20">{renderChecklist()}</div> : null}
      <div className="w-full pt-4 text-left">
        <Button
          type="submit"
          background="green"
          color="white"
          title={allChecked ? t('global:confirm')! : t('global:save')!}
          size="2xl"
          round="4xl"
          onClick={handleOnSubmit}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
