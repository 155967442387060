import { Image } from 'components/Image';

interface Props {
  contentUrl?: string;
  imagePath: string;
  imageTitle?: string;
  title: string;
  description: string;
}

const RowCard = ({ contentUrl = '#', imagePath, imageTitle = '', title, description }: Props) => {
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a href={contentUrl} target="_blank">
      <div className="flex min-w-285 flex-1 flex-col  flex-nowrap gap-5 rounded-xl border border-gray-200 bg-white p-3 shadow-md md:min-w-520 md:flex-row md:items-center">
        <div className="w-36 self-center md:flex-basis-150">
          <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg">
            <Image src={imagePath} alt={imageTitle ? imageTitle : title} />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <h1 className="max-h-14 overflow-hidden text-xl font-bold text-primary">{title}</h1>
          <p className="max-h-20 overflow-hidden text-sm text-gray-500">{description}</p>
        </div>
      </div>
    </a>
  );
};

export default RowCard;
