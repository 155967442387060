import { FC, ReactElement } from 'react';
interface AuthLayoutProps {
  children: ReactElement;
}

const AuthLayout: FC<AuthLayoutProps> = ({ children }: AuthLayoutProps) => {
  return (
    <div className="relative flex h-full min-h-screen w-full flex-col items-center justify-center">
      <div className="absolute top-0 left-0 right-0 z-0 h-full w-full overflow-hidden">
        <img
          className="opacity-1 z-0 min-h-full min-w-full object-cover"
          src="/assets/img/auth-bg-img.jpg"
          alt="Signin Background"
        />
      </div>
      {children}
    </div>
  );
};

export default AuthLayout;
