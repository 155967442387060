import { ReactElement, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import momentDateFormater from 'moment';

// Import recoil atoms
import { userAtom } from 'store/userAtom';

// Import types
import { ChangeProfileData } from 'common/types/User.type';

// Import services
import userService from 'services/user.service';

// Import Components
import SubHeader from 'components/SubHeader/SubHeader';
import Header from 'components/Header/Header';
import Paragraph from 'components/Paragraph/Paragraph';
import { Input } from 'components/Form/Input';
// import { Checkbox } from 'components/Form/Checkbox';
import { ReactComponent as EditIcon } from 'assets/svg-icons/edit-icon.svg';
import { Alert, AlertType } from 'components/Alert';
import { useTranslation } from 'react-i18next';

const formSchema = yup.object().shape({
  firstName: yup.string().required('The first name is required!'),
  lastName: yup.string().required('The last name is required!'),
  birthday: yup.string().required('The birth day is required!'),
});

interface Props {
  className?: string;
}

const EditProfilePage = ({ className = '' }: Props): ReactElement => {
  const { t } = useTranslation('pages');
  const { user } = useRecoilValue(userAtom);
  const setUser = useSetRecoilState(userAtom);
  const navigate = useNavigate();
  momentDateFormater.locale('en');

  // State
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  // Form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangeProfileData>({ resolver: yupResolver(formSchema) });

  // Handlers
  const handleChangeProfileSubmit = async (data: ChangeProfileData) => {
    try {
      data.birthday = momentDateFormater(data.birthday).format('YYYY-MM-DDThh:mm:ss.sssZ');
      const message = await userService.patchProfileData(user?.id || '', data);
      setMessage(message);

      // set the user profile
      const newUserProfile = await userService.getUserByEmail(user?.email || '');
      setUser({ user: newUserProfile });

      // redirect the user back to My Profile page
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setTimeout(() => {
        navigate('/settings/profile');
      }, 2000);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setError(error.message);
    }
  };

  let formatedBirthDay = new Date();
  if (user?.profile?.birthday)
    formatedBirthDay = new Date(
      momentDateFormater(user?.profile?.birthday).format('YYYY-MM-DDThh:mm:ss.sssZ')
    );

  return (
    <div className={className}>
      <Header className="mb-5 text-26 " title={t('settings.editProfilePage.myMTGProfile')} />
      <div className="relative mb-5 rounded-4xl bg-transparent bg-white lg:bg-white lg:p-7 lg:shadow-md">
        <SubHeader
          className="mb-3 text-2xl text-primary md:text-3xl"
          title={t('settings.editProfilePage.email')!}
        />
        <div className="mb-5 flex items-center justify-between">
          <div className="flex w-full flex-1 flex-col rounded-2xl bg-white pb-3 lg:mr-5 lg:flex-row lg:py-3 lg:px-5 lg:shadow">
            <span className="pb-3 text-base text-gray-700 md:text-2xl.5 lg:pb-0">
              {t('settings.editProfilePage.yourEmail')}:&nbsp;
            </span>
            <span className=" flex w-full items-center break-all rounded-2xl px-5 py-3 text-lg font-medium text-primary shadow lg:w-fit lg:rounded-none lg:py-0 lg:px-0 lg:text-2xl.5 lg:shadow-none">
              {user?.email}
            </span>
          </div>
          {/* <div className="self-stretch hidden px-5 py-3 light-blue-gradient rounded-2xl lg:flex">
            <span className="flex items-center font-medium text-white">Edit Email Address</span>
          </div> */}
          <Link
            className="absolute top-0 right-0 -translate-y-1/2 transform rounded-2xl bg-blue-200 p-3 lg:hidden"
            to="#"
          >
            <EditIcon />
          </Link>
        </div>
        <SubHeader className="mb-3 text-2xl text-primary md:text-3xl" title={t('Your profile')!} />
        <Alert message={error} />
        <Alert type={AlertType.SUCCESS} message={message} />
        <form onSubmit={handleSubmit(handleChangeProfileSubmit)} className="flex flex-col">
          <Paragraph
            text={t('settings.editProfilePage.firstName')}
            className="my-5 text-base text-gray-700"
          />
          <Input
            autoComplete="off"
            type="text"
            placeholder={t('settings.editProfilePage.firstName')}
            className="mb-5"
            inputClassName="w-full leading-none rounded-2xl bg-white py-3 px-8 text-lg shadow lg:text-2xl.5"
            removeDefaultInputClass
            defaultValue={user?.profile?.firstName}
            {...register('firstName')}
            errors={errors}
          />
          <Paragraph
            text={t('settings.editProfilePage.lastName')}
            className="my-5 text-base text-gray-700"
          />
          <Input
            autoComplete="off"
            type="text"
            placeholder={t('settings.editProfilePage.lastName')}
            className="mb-5"
            inputClassName="w-full leading-none rounded-2xl bg-white py-3 px-8 text-lg shadow lg:text-2xl.5"
            removeDefaultInputClass
            defaultValue={user?.profile?.lastName}
            {...register('lastName')}
            errors={errors}
          />
          <Paragraph
            text={t('settings.editProfilePage.dateOfBirth')}
            className="my-5 text-base text-gray-700"
          />
          <Input
            autoComplete="off"
            type="date"
            placeholder={t('settings.editProfilePage.dateOfBirth')}
            className="mb-5"
            inputClassName="w-full leading-none rounded-2xl bg-white py-3 px-8 text-lg shadow lg:text-2xl.5"
            removeDefaultInputClass
            defaultValue={formatedBirthDay.toISOString().substr(0, 10)}
            {...register('birthday')}
            errors={errors}
          />
          <button className="light-blue-gradient self-center rounded-2xl.5 px-10 py-6 text-xl font-medium text-white">
            {t('settings.editProfilePage.changeProfile')}
          </button>
        </form>
      </div>
      {/* <div className="bg-transparent bg-white rounded-4xl lg:bg-white lg:p-7 lg:shadow-md">
        <SubHeader title="Privacy" className="text-2xl md:text-3xl " />
        <div className="mt-5">
          <Checkbox
            className="mb-3"
            label="Show your profile to logged-in users"
            name="logged-in"
          />
          <Checkbox
            checked
            label="Show courses you're taking on your profile page"
            name="show-courses"
          />
        </div>
      </div> */}
    </div>
  );
};

export default EditProfilePage;
