import api from 'utils/api';

// Import types
import { Lesson } from 'common/types/Lesson.type';

class LessonService {
  getLesson = async (courseId: string, moduleId: string, lessonId: string): Promise<Lesson> => {
    const res: Lesson = await api.get(
      `/api/student/course/${courseId}/module/${moduleId}/lesson/${lessonId}`
    );
    return res;
  };

  getPreviousLesson = async (lessonId: string): Promise<Lesson> => {
    const res: Lesson = await api.get(`/api/lesson/${lessonId}/previous_lesson`);
    return res;
  };

  getNextLesson = async (lessonId: string): Promise<Lesson> => {
    const res: Lesson = await api.get(`/api/lesson/${lessonId}/next_lesson`);
    return res;
  };

  hasNextLesson = async (lessonId: string): Promise<boolean> => {
    const res: boolean = await api.get(`/api/lesson/${lessonId}/has_next_lesson`);
    return res;
  };
}

export default new LessonService();
