import { ReactElement, ReactNode } from 'react';

// Import Type
export interface ButtonProps {
  className?: string;
  title?: string;
  type?: 'submit' | 'button';
  round?: 'none' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl';
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'none';
  background?: 'blue' | 'white' | 'black' | 'green' | 'none' | 'default';
  color?: 'white' | 'black' | 'none';
  children?: ReactNode | ReactElement | string;
  full?: boolean;
  disabledRing?: boolean;
  onClick?: () => void;
}

export type ButtonPropsType = Omit<React.HTMLProps<HTMLButtonElement>, 'ref' | 'size'> &
  ButtonProps;

const ROUND_CLASSES = {
  none: '',
  sm: 'rounded-sm',
  md: 'rounded-md',
  lg: 'rounded-lg',
  xl: 'rounded-xl',
  '2xl': 'rounded-2xl',
  '3xl': 'rounded-3xl',
  '4xl': 'rounded-4xl',
};
const COLOR_CLASSES = {
  white: 'focus:ring-gray-200 text-white',
  black: 'focus:ring-gray-200 text-primary',
  none: '',
};
const BACKGROUND_CLASSES = {
  blue: 'focus:ring-blue-200 blue-gradient ',
  white: 'focus:ring-gray-200 white-gradient ',
  black: 'focus:ring-gray-200 black-gradient ',
  green: 'focus:ring-green-200 green-gradient ',
  default: 'text-black',
  none: '',
};

const SIZE_CLASSES = {
  sm: 'py-2 px-4 text-sm',
  md: 'py-3 px-5 text-md font-normal',
  lg: 'py-4 px-6 text-lg font-medium',
  xl: 'py-5 px-7 text-xl font-bold',
  '2xl': 'py-4 md:py-6 px-24 md:px-32 text-xl md:text-2xl.5 font-bold',
  none: 'py-2 text-sm',
};

const Button = ({
  className,
  title,
  type = 'button',
  round = 'md',
  children = null,
  full = false,
  background = 'default',
  color = 'none',
  size = 'sm',
  onClick,
  disabled = false,
  disabledRing = false,
  ...props
}: ButtonPropsType): ReactElement => (
  <button
    onClick={onClick}
    type={type}
    className={`
        ${full ? 'w-full' : 'w-fit'}
        ${
          disabledRing
            ? 'focus:outline-none flex flex-row items-center justify-center border-0 border-transparent font-normal leading-none'
            : 'focus:outline-none flex flex-row items-center justify-center border-0 border-transparent font-normal leading-none focus:ring-2 focus:ring-offset-2'
        }
        ${SIZE_CLASSES[size]}
        ${ROUND_CLASSES[round]}
        ${BACKGROUND_CLASSES[background]}
        ${COLOR_CLASSES[color]}
        ${className ? className : ''}
        ${disabled ? 'pointer-events-none cursor-not-allowed opacity-50' : ''}
      `}
    {...props}
  >
    <span>{title}</span>
    {children}
  </button>
);

export default Button;
