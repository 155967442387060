import { ReactElement } from 'react';

// Import types
import { ListSectionData } from 'common/types/RenderEngine.type';

// Import components
import { Image } from 'components/Image';
import SubHeader from 'components/SubHeader/SubHeader';
import Header from 'components/Header/Header';
import Paragraph from 'components/Paragraph/Paragraph';

// Interfaces
export interface ListSectionProps {
  data: ListSectionData;
}

export default function ListSection({
  data: { header, subHeader, paragraph, backgroundColor, image, list },
}: ListSectionProps): ReactElement<ListSectionProps> {
  // Renderes
  const renderListItems = () => {
    if (!list?.length) return null;
    return list.map((item, index) => (
      <li
        key={item.id}
        className={`${
          index + 1 === list.length ? '' : 'border-l-2'
        } ml-sm.5 border-gray-500 pb-16 pl-8 leading-8  `}
      >
        <span className="absolute left-0 flex h-5 w-5 items-center justify-center rounded-full border-2 border-gray-primary bg-white-900 p-3 text-gray-500 ">
          {index + 1}
        </span>
        {item.content}
      </li>
    ));
  };

  return (
    <div className="flex flex-col items-stretch justify-between xl:flex-row">
      <div
        style={{
          ...(backgroundColor && { backgroundColor }),
        }}
        className="flex w-full flex-col justify-center bg-white-900 px-4 py-16 md:px-36 xl:w-5/12"
      >
        {subHeader && <SubHeader className="mb-3" title={subHeader} />}
        {header && <Header className="mb-5" title={header} />}
        {paragraph && <Paragraph className="mb-5" text={paragraph} />}
        <ul className="relative">{renderListItems()}</ul>
      </div>
      {image && (
        <div
          className="w-full xl:w-7/12"
          style={{
            ...(backgroundColor && { backgroundColor }),
          }}
        >
          <div className="aspect-w-4 aspect-h-4 min-h-full overflow-hidden bg-white-900 xl:aspect-w-16 xl:aspect-h-12">
            <Image
              className="h-full w-full"
              imageClassName="object-cover min-w-full h-auto"
              alt={image.title || ''}
              src={image.path}
            />
          </div>
        </div>
      )}
    </div>
  );
}
