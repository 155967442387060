import api from 'utils/api';

class PaymentService {
  newCheckoutSession = async (programId: string, userId: string): Promise<string> => {
    const session: string = await api.post('api/stripe/create-checkout-session', {
      programId,
      userId,
    });
    return session;
  };

  confirmSubscription = async (
    sessionId: string,
    programId: string,
    userId: string
  ): Promise<string> => {
    const result: string = await api.post('api/stripe/confirm-subscription', {
      sessionId,
      programId,
      userId,
    });
    return result;
  };
}

export default new PaymentService();
