import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// EN locales
import resourcesEn from 'locales/en/resources.json';
import navigationEn from 'locales/en/navigation.json';
import pagesEn from 'locales/en/pages.json';
import globalEn from 'locales/en/global.json';
import componentsEn from 'locales/en/components.json';
// DE locales
import resourcesNo from 'locales/no/resources.json';
import navigationNo from 'locales/no/navigation.json';
import pagesNo from 'locales/no/pages.json';
import globalNo from 'locales/no/global.json';
import componentsNo from 'locales/no/components.json';

const resources = {
  en: {
    navigation: navigationEn,
    resources: resourcesEn,
    pages: pagesEn,
    global: globalEn,
    components: componentsEn,
  },
  no: {
    navigation: navigationNo,
    resources: resourcesNo,
    pages: pagesNo,
    global: globalNo,
    components: componentsNo,
  },
};

// i18N Initialization
// eslint-disable-next-line import/no-named-as-default-member
i18n.use(initReactI18next).init({
  resources,
  lng: 'no',
  fallbackLng: 'no',
  keySeparator: '.',

  ns: ['navigation', 'resources', 'pages', 'global', 'components'],
});

export default i18n;
