import React, { ForwardedRef, ReactElement, useState, forwardRef } from 'react';
import ReactPlayer from 'react-player';

// Import Type
import { VideoPlayerType } from 'common/types/RenderEngine.type';

// Import components
import { ReactComponent as VideoPlayIcon } from 'assets/svg-icons/play-video-icon.svg';
import { Image } from 'components/Image';
import { useTranslation } from 'react-i18next';

export interface VideoPlayerProps {
  className?: string;
  videoClassName?: string;
  data: VideoPlayerType;
  onVideoPlay?: () => void;
}
function VideoPlayer(
  { className = '', videoClassName = '', data: { path, poster }, onVideoPlay }: VideoPlayerProps,
  ref: ForwardedRef<ReactPlayer>
): ReactElement<VideoPlayerProps> {
  // State
  const [showVideo, setShowVideo] = useState(false);
  const { t } = useTranslation();

  // Handlers
  const handleShowVideo = () => {
    setShowVideo(true);
    if (onVideoPlay) onVideoPlay();
  };
  return (
    <div
      className={`items-center justify-center overflow-hidden bg-black-100 object-cover align-middle ${className}`}
    >
      <div
        className={`${
          showVideo ? '' : 'hidden'
        } ml-auto mr-auto h-full w-auto bg-transparent ${videoClassName}`}
      >
        <ReactPlayer
          controls
          controlsList="nodownload"
          playing={showVideo}
          url={path}
          width="100%"
          height="100%"
          ref={ref}
        >
          {t('global:notSupportedVideo')}
        </ReactPlayer>
      </div>
      {!showVideo && (
        <span
          onClick={handleShowVideo}
          className="flex h-full w-full cursor-pointer items-center justify-center object-cover align-middle"
        >
          <div className="z-10 flex h-full w-full items-center justify-center align-middle opacity-70 transition-opacity hover:opacity-100">
            <VideoPlayIcon className="h-14 min-w-full md:h-32" />
          </div>
          <Image
            src={poster?.path}
            className="absolute z-0 h-full min-h-full w-auto min-w-full"
            alt="Video poster"
          />
        </span>
      )}
    </div>
  );
}

export default forwardRef(VideoPlayer);
