import { ReactElement } from 'react';

// Import Component
import Header from 'components/Header/Header';
import { Image } from 'components/Image';
import SectionDivider from 'components/SectionDivider/SectionDivider';
import SubHeader from 'components/SubHeader/SubHeader';
import Paragraph from 'components/Paragraph/Paragraph';
import { useTranslation } from 'react-i18next';

const PaymentSuccessEmail = (): ReactElement => {
  const { t } = useTranslation('pages');
  return (
    <div className="z-50 h-full w-full bg-gray-400">
      <Image
        className="absolute top-0 left-0 right-0 z-10"
        alt="bg-img"
        src="/assets/img/course-header.jpg"
      />
      <div className="relative z-20 mx-20 flex flex-col items-center py-10 lg:mx-72">
        <img src="/mtg-logo.png" className="" alt="MTG Logo" />
        <div className="relative my-10">
          <img
            className="absolute top-0 left-0 z-20 -translate-x-1/2 -translate-y-1/3 scale-50 transform object-cover md:-translate-x-1/2 md:-translate-y-1/2 lg:scale-100"
            src="/assets/img/leaf-topleft.svg"
            alt="Checkout Background"
          />
          <img
            className="absolute top-0 right-0 z-50 translate-x-1/2 translate-y-1/2 scale-50 transform object-cover md:translate-x-1/2 lg:scale-75"
            src="/assets/img/leaf-topright.svg"
            alt="Checkout Background"
          />
          <img
            className="absolute bottom-0 left-0 z-20 -translate-x-10 translate-y-1/2 scale-50 transform object-cover lg:scale-100"
            src="/assets/img/leaf-botleft.svg"
            alt="Checkout Background"
          />
          <div className="relative z-40 overflow-hidden rounded-4xl bg-white">
            <div className="relative">
              <Image
                className="h-screenemail"
                src="/assets/img/background-lesson.jpg"
                alt="welcome"
              />
              <div className="absolute left-0 right-0 bottom-10 mx-auto mb-20 h-fit w-full text-center">
                <Header className="mb-7" isWhite title={t('payment.successEmail.title')} />
                <SubHeader className="text-white-800" title={t('payment.successEmail.subTitle')!} />
              </div>
              <div className="absolute left-0 right-0 -bottom-1 z-20 flex h-fit w-full">
                <SectionDivider className="w-full" />
              </div>
            </div>
            <div className="px-8 py-10">
              <Header
                className="mb-10 text-center"
                fontWeight="font-semibold"
                title={t('payment.successEmail.orderDetails')}
              />
              <div className="flex flex-col divide-y">
                <div className="flex flex-col justify-between gap-2 py-2 md:flex-row">
                  <div className="flex flex-row gap-2">
                    <Paragraph text={t('payment.successEmail.orderNumber')} />
                    <Paragraph text="#000000" />
                  </div>
                  <div className="flex flex-row gap-2">
                    <Paragraph text={t('payment.successEmail.orderDate')} />
                    <Paragraph text="25/04/2022" />
                  </div>
                </div>
                <div className="flex flex-col gap-2 py-2 md:flex-row">
                  <div className="flex-1 ">
                    <div className="pb-5">
                      <Paragraph text={t('payment.successEmail.item')} />
                    </div>
                    <div>
                      <SubHeader
                        title="MadeToGrow - 1 Year Subscription"
                        className="mb-2 text-2xl capitalize md:text-3xl"
                      />
                      <SubHeader
                        title="Nok 600,000"
                        className="mb-2 text-2xl capitalize text-secondary md:text-3xl"
                      />
                      <SubHeader
                        title="Billed Monthly"
                        className="mb-2 text-xl capitalize text-red-100 md:text-2xl"
                      />
                    </div>
                  </div>
                  <div className="md:text-center">
                    <div className="pb-5">
                      <Paragraph text={t('payment.successEmail.quantity')} />
                    </div>
                    <div>
                      <SubHeader title="1" className="text-2xl capitalize md:text-3xl" />
                    </div>
                  </div>
                  <div className="flex-basis-1/3 flex-grow-0 md:text-right">
                    <div className="pb-5">
                      <Paragraph text={t('payment.successEmail.cost')} />
                    </div>
                    <div>
                      <SubHeader title="Nok 50,000" className="text-2xl capitalize md:text-3xl" />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-2 py-2 md:flex-row">
                  <div className="hidden flex-1 md:flex"></div>
                  <div className="text-left">
                    <Paragraph text={t('payment.successEmail.subTotal')} />
                    <Paragraph text={t('payment.successEmail.tax')} />
                    <Paragraph text={t('payment.successEmail.total')} />
                  </div>
                  <div className="flex-basis-1/3 md:text-right">
                    <Paragraph className="text-primary" text="Nok 50,000" />
                    <Paragraph className="text-primary" text="Nok 5,000" />
                    <Paragraph className="text-primary" text="Nok 55,000" />
                  </div>
                </div>
                <div className="flex flex-col py-2 ">
                  <Paragraph
                    text={t('payment.successEmail.billedTo')}
                    className=" mb-7"
                    fontWeight="font-medium"
                  />
                  <Paragraph text="Sissel Naustdal" fontWeight="font-light" />
                  <Paragraph text="Master Card" fontWeight="font-light" />
                  <Paragraph text="xxxx-xxxx-xxxx-0000" fontWeight="font-light" />
                  <Paragraph text="Oslo Norway" fontWeight="font-light" />
                  <Paragraph text="Address" fontWeight="font-light" />
                  <Paragraph text="Zip 00000" fontWeight="font-light" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p>{t('welcome.footerText')}</p>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessEmail;
