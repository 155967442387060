import React, { ReactElement } from 'react';

import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const ChangeLanguage = (): ReactElement => {
  const { i18n } = useTranslation();

  const lang = i18n.language;
  const handleChangeLanguage = () => {
    if (lang === 'no') {
      return i18n.changeLanguage('en');
    }
    i18n.changeLanguage('no');
  };
  return (
    <span
      onClick={handleChangeLanguage}
      className="focus:outline-none flex w-full items-center justify-center space-x-2 py-1 text-white focus:text-white-900"
    >
      <FontAwesomeIcon icon={faGlobe} size="lg" />
      <p className="text-base leading-4 ">{lang === 'no' ? 'EN' : 'NO'}</p>
    </span>
  );
};

export default ChangeLanguage;
