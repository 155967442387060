import api from 'utils/api';

// Import types
import { Program } from 'common/types/Program.type';

class ProgramsService {
  getProgramByCourseId = async (courseId: string): Promise<Program> => {
    const res: Program = await api.get(`api/student/program/${courseId}`);
    return res;
  };
}

export default new ProgramsService();
