// Import types
import { UserRole } from 'common/types/User.type';

// Declare interfaces
export interface ConfigRoute {
  path: string;
  basePath?: string;
  roles: UserRole[];
}

export interface ConfigRoutes {
  [key: string]: {
    [key: string]: ConfigRoute;
  };
}

// Declare config
const routes: ConfigRoutes = {
  auth: {
    signIn: {
      path: '/auth/sign-in',
      roles: [],
    },
    signUp: {
      path: '/auth/sign-up',
      roles: [],
    },
    resetPassword: {
      path: '/reset-password',
      roles: [],
    },
  },

  payment: {
    success: {
      path: '/payment/success',
      roles: [],
    },
    failed: {
      path: '/payment/failed/:programId',
      roles: [],
    },
    payment: {
      path: '/payment/email-success',
      roles: [],
    },
  },
  checkout: {
    course: {
      path: '/checkout/:courseId',
      roles: [],
    },
  },
  welcome: {
    index: {
      path: '/welcome',
      roles: [],
    },
  },
  course: {
    course: {
      path: '/course/:courseId/*',
      basePath: '/course/:courseId',
      roles: [],
    },
    overview: {
      path: '/overview',
      basePath: '/course/:courseId',
      roles: [],
    },
    modules: {
      path: '/modules',
      basePath: '/course/:courseId',
      roles: [],
    },
    lesson: {
      path: '/view/course/:courseId/module/:moduleId/lesson/:lessonId',
      roles: [],
    },
  },
  settings: {
    index: {
      path: '/settings',
      roles: [],
    },
    edit: {
      path: '/edit',
      roles: [],
    },
    profile: {
      path: '/profile',
      roles: [],
    },
    editProfile: {
      path: '/editProfile',
      roles: [],
    },
    courses: {
      path: '/courses',
      roles: [],
    },
    payment: {
      path: '/payment',
      roles: [],
    },
    certificates: {
      path: '/certificates',
      roles: [],
    },
  },
  userHomePage: {
    // index: {
    //   path: '/',
    //   roles: [],
    // },
    dashboard: {
      path: '/',
      roles: [],
    },
  },
  freeContent: {
    index: {
      path: '/content',
      roles: [],
    },
    item: {
      path: '/content/:freeContentId',
      roles: [],
    },
  },
};

export default routes;
