import { ReactElement } from 'react';

// Import components
import Header from 'components/Header/Header';
import SubHeader from 'components/SubHeader/SubHeader';
import Paragraph from 'components/Paragraph/Paragraph';
import { Button } from 'components/Button';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import { ReactComponent as Nexticon } from 'assets/svg-icons/next-icon.svg';
import { Image } from 'components/Image';
import SectionDivider from 'components/SectionDivider/SectionDivider';

// Import types
import { LessonHeroData } from 'common/types/RenderEngine.type';

export interface LessonHeroProps {
  data: LessonHeroData;
  coverHeader?: boolean;
}

const LessonHero = ({ data, coverHeader = true }: LessonHeroProps): ReactElement | null => {
  const { header, subHeader, paragraph, sectionDivider, backgroundImage, video, callToAction } =
    data;

  // Handlers
  const handleCallToAction = () => {
    if (!callToAction) return null;
    window.open(callToAction.path, '_blank');
  };
  return (
    <div
      className={`
        relative h-auto w-full bg-gray-800
        ${sectionDivider ? 'mb-0 lg:-mb-20' : ''}
        ${coverHeader ? 'z-40 -mt-28 pt-36' : ''}
      `}
    >
      {backgroundImage && (
        <div className="absolute top-0 right-0 bottom-0 left-0 z-0 h-full w-full overflow-hidden">
          <Image
            className="h-full w-full "
            alt={backgroundImage.title || ''}
            src={backgroundImage.path}
          />
        </div>
      )}
      <div className="relative z-10 flex h-auto w-full flex-col  md:flex-row md:py-20">
        <div className="flex flex-1 items-center pt-5 md:pt-0">
          <div className="w-full pl-4 md:pl-36">
            {subHeader && (
              <SubHeader isWhite className="mb-5 text-xl md:text-3xl" title={subHeader} />
            )}
            {header && <Header isWhite className="mb-10" fontWeight="font-bold" title={header} />}
            {paragraph && <Paragraph isWhite text={paragraph} className="mb-5 text-white" />}
            {callToAction ? (
              <Button
                className="absolute bottom-0 border pl-0 text-white md:relative "
                type="button"
                size="lg"
                color="white"
                onClick={handleCallToAction}
              >
                <SubHeader isWhite title={callToAction.label} className="text-xl md:text-3xl" />
                <Nexticon className="ml-5" />
              </Button>
            ) : null}
          </div>
        </div>
        <div className="flex h-auto w-full flex-1 items-center">
          {video && (
            <div className="ml-auto mt-10 mb-20 w-10/12 md:mx-0 md:mb-0 md:mt-0 md:ml-auto">
              <div className="aspect-w-16 aspect-h-10">
                <VideoPlayer
                  data={video}
                  className=" rounded-2xl.5 rounded-tr-none rounded-br-none object-cover"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {sectionDivider ? (
        <div className="relative -bottom-1 z-30 w-full">
          <SectionDivider />
        </div>
      ) : null}
    </div>
  );
};
export default LessonHero;
