import api from '../utils/api';

// Import type
import { AuthRequest } from 'common/types/Auth.type';
import { User, CreateUser } from 'common/types/User.type';

type SignInSignUpResponse = {
  accessToken: string;
  user: User;
};

type ResetPasswordResponse = {
  responseMessage: string;
};

class AuthService {
  /**
   *
   * Checks if user is signed in
   * @return {boolean} if user signed in
   */
  isSignedIn = () => {
    return localStorage.getItem('mtgp_token') !== null;
  };

  /**
   * return user auth access token
   * @return {string} access token
   */
  getToken = () => {
    return localStorage.getItem('mtgp_token');
  };

  /**
   * sign in user
   * @param {AuthRequest} authRequest - user email, password and rememberMe
   */
  async signIn(authRequest: AuthRequest) {
    const res: SignInSignUpResponse = await api.post('api/auth/signin', authRequest);
    if (res) {
      this.saveAuthToLocalStorage(res);
    }
  }

  /**
   * return user auth user
   * @return {SignInSignUpResponse.user} user
   */
  getUser = (): User | null => {
    return JSON.parse(localStorage.getItem('mtgp_user') || 'null') as User | null;
  };

  /**
   * sign out current user
   */
  signOut = () => {
    localStorage.removeItem('mtgp_token');
    localStorage.removeItem('mtgp_user');
  };

  async signUp(data: CreateUser): Promise<SignInSignUpResponse> {
    delete data.confirmPassword;
    const res: SignInSignUpResponse = await api.post('api/auth/signup', data);
    if (res) {
      this.saveAuthToLocalStorage(res);
    }
    return res;
  }

  /**
   * reset password
   * @param {string} email email of user
   */
  async resetPassword(email: string): Promise<void> {
    const res: ResetPasswordResponse = await api.post('api/user/reset-password', { email });

    // eslint-disable-next-line no-empty
    if (res) {
    }
  }

  saveAuthToLocalStorage = (data: SignInSignUpResponse) => {
    localStorage.setItem('mtgp_token', data.accessToken);
    localStorage.setItem('mtgp_user', JSON.stringify(data.user));
  };
}

export default new AuthService();
