import { ReactElement, forwardRef, ForwardedRef, useState } from 'react';
import { get as _get } from 'lodash';

// Import
import { SvgIconList, SvgIcon } from 'components/Icon/SvgIcon';

// Interfaces
export interface InputProps {
  id?: string;
  placeholder: string;
  type?: 'text' | 'password' | 'email' | 'number' | 'date';
  name: string;
  autoComplete?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: any;
  className?: string;
  inputClassName?: string;
  removeDefaultInputClass?: boolean;
}

export type InputPropsType = Omit<React.HTMLProps<HTMLInputElement>, 'ref'> & InputProps;

const Input = (
  {
    type = 'text',
    placeholder,
    errors = null,
    name,
    className = '',
    inputClassName = '',
    removeDefaultInputClass = false,
    ...props
  }: InputPropsType,
  ref: ForwardedRef<HTMLInputElement>
): ReactElement => {
  const [showPassowrd, setShowPassowrd] = useState(false);
  const error = _get(errors, name);

  const _inputClassName = removeDefaultInputClass
    ? ''
    : 'focus:outline-none h-12 w-full rounded-4xl border border-inputColor py-3 px-5 text-base font-normal leading-none placeholder-primary text-gray-800';
  return (
    <div className="flex-1">
      <div className={`relative flex flex-col items-start justify-center ${className}`}>
        <input
          type={type !== 'password' ? type : showPassowrd ? 'text' : type}
          placeholder={placeholder}
          name={name}
          className={`${_inputClassName} ${inputClassName}`}
          ref={ref}
          {...props}
        />
        {/* reveal password icon is hidden*/}
        {type === 'password' && (
          <div
            className="absolute top-4 right-0 mr-3 cursor-pointer"
            onClick={() => setShowPassowrd(!showPassowrd)}
          >
            {!showPassowrd ? (
              <SvgIcon name={SvgIconList.EYE} />
            ) : (
              <SvgIcon
                className="-mt-1 -mr-1 scale-75 transform"
                name={SvgIconList.EYECLOSE}
                width={24}
                height={24}
              />
            )}
          </div>
        )}
        {error && <span className="mt-2 ml-2 text-xs text-red-600">{error.message}</span>}
      </div>
    </div>
  );
};

export default forwardRef(Input);
