import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import momentDateFormater from 'moment';

// Import recoil atoms
import { userAtom } from 'store/userAtom';

// Import components
import { ReactComponent as EditIcon } from 'assets/svg-icons/edit-icon.svg';
import Header from 'components/Header/Header';
import Paragraph from 'components/Paragraph/Paragraph';
import { useTranslation } from 'react-i18next';

export interface Props {
  className?: string;
}

const MyProfilePage = ({ className = '' }: Props): ReactElement => {
  const { t } = useTranslation('pages');
  const { user } = useRecoilValue(userAtom);
  momentDateFormater.locale('en');
  let formatedBirthDay = '';
  if (user?.profile?.birthday)
    formatedBirthDay = momentDateFormater(user?.profile?.birthday).format('DD/MM/YYYY');

  return (
    <div className={className}>
      {/* Dynamic Title for PageProfile */}
      <Header className="" title={t('settings.myProfilePage.myMTGProfile')} />
      <div className="mt-5 rounded-4xl bg-transparent lg:bg-white lg:p-7 ">
        <div className="relative flex lg:mb-10">
          <div className="mb-3 flex-1 lg:mb-0">
            <Paragraph
              className="text-primary md:text-3xl lg:mb-3"
              text={user?.profile?.firstName + ' ' + user?.profile?.lastName}
            />
            <Paragraph text={user?.email} className="text-gray-700" />
          </div>
          <div className="self-center">
            <Link
              className="absolute top-0 right-0 -translate-y-1/2 transform rounded-2xl bg-blue-200 p-3 lg:hidden"
              to="#"
            >
              <EditIcon />
            </Link>
            <Link
              to="/settings/editProfile"
              className="light-blue-gradient hidden rounded-3xl px-8 py-3 text-2xl font-medium text-white lg:block"
            >
              {t('settings.myProfilePage.changeYourProfile')}
            </Link>
          </div>
        </div>
        <div className="flex flex-col lg:mb-10 lg:flex-row">
          <div className="mb-3 flex-1 lg:mb-0">
            <Paragraph
              className="text-primary lg:mb-3"
              text={t('settings.myProfilePage.dateOfBirth')}
            />
            <Paragraph text={formatedBirthDay} className="text-gray-700" />
          </div>
          <div className="mb-3 flex-1 lg:mb-0">
            <Paragraph
              className="text-primary lg:mb-3"
              text={t('settings.myProfilePage.memberPage')}
            />
            <Paragraph
              text={momentDateFormater(user?.profile?.createdAt).format('DD/MM/YYYY')}
              className="text-gray-700"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfilePage;
