export enum FeaturedEnum {
  COURSE = 'COURSE',
  FREE_CONTENT = 'FREE_CONTENT',
}

export interface FeaturedType {
  id: string;
  title: string;
  description: string;
  imagePath: string;
  type: FeaturedEnum;
  updatedAt: Date;
}
