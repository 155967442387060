import api from 'utils/api';
import {
  Subscription,
  GetSubscription,
  GetAllSubscriptions,
} from '../common/types/Subscription.type';

class SubscriptionService {
  getSubscription = async (userId: string, courseId: string): Promise<Subscription> => {
    const request: GetSubscription = { userId, courseId };
    const res: Subscription = await api.post('api/subscription/student', request);
    return res;
  };

  getAllSubscriptions = async (userId: string): Promise<Subscription[]> => {
    const request: GetAllSubscriptions = { userId };
    const res: Subscription[] = await api.post('api/subscription/student/all', request);
    return res;
  };
}

export default new SubscriptionService();
