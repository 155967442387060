import { ReactChild, ReactElement, ReactNode } from 'react';

type Props = {
  wrapperClassName?: string;
  className?: string;
  children: boolean | ReactElement | ReactChild | Array<ReactElement | ReactChild> | ReactNode;
};

export const PageContent = ({
  className = '',
  wrapperClassName = '',
  children,
}: Props): ReactElement => {
  return (
    <div className={`${wrapperClassName}`}>
      <div className={`mx-auto flex w-11/12 flex-col gap-10 py-5 md:w-10/12 ${className}`}>
        {children}
      </div>
    </div>
  );
};

export default PageContent;
