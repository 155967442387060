import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

// Import settings etc..
import routes from 'config/routesConfig';
import { configureAPI } from 'utils/api';

// import Swiper core and required modules
import 'swiper/swiper-bundle';
import 'swiper/modules/pagination/pagination';

// Import recoil state
import { userAuth } from 'store/auth';

// Import Layouts
import Layout from './layouts/Layout';

// Import Pages
import { Signin } from 'pages/Auth/Signin/index';
import { Signup } from 'pages/Auth/Signup/index';
import { ResetPassword } from 'pages/Auth/ResetPassword/index';
import { Checkout } from 'pages/Checkout';
import { LessonPage } from 'pages/Lesson';
import { Course } from 'pages/Course';
import { SettingsPage } from 'pages/Settings';
import { PaymentSuccessPage, PaymentFailedPage, PaymentSuccessEmail } from 'pages/Payment/index';
import { WelcomePage } from 'pages/Welcome/index';
import { UserDashboardPage } from 'pages/UserHome';
import { FreeContent } from 'pages/FreeContent';

// Initialize
const queryClient = new QueryClient();

/**
 * App component returning root react component
 * @return {JSX.Element} root react component
 */
function App(): JSX.Element {
  const auth = useRecoilValue(userAuth);
  const setAuth = useSetRecoilState(userAuth);
  const navigate = useNavigate();

  configureAPI({ onSignOut: () => setAuth({ isAuthenticated: false }), navigate });

  return (
    <QueryClientProvider client={queryClient}>
      <Layout auth={auth}>
        {/* Auth Routes */}
        {!auth.isAuthenticated && (
          <Routes>
            <Route path={routes.auth.signIn.path} element={<Signin />} />
            <Route path={routes.auth.signUp.path} element={<Signup />} />
            <Route path={routes.auth.resetPassword.path} element={<ResetPassword />} />
            <Route path="*" element={<Navigate to={routes.auth.signIn.path} />} />
          </Routes>
        )}

        {auth.isAuthenticated && (
          <Routes>
            <Route path={routes.freeContent.index.path} element={<FreeContent />} />
            <Route path={routes.freeContent.item.path} element={<FreeContent />} />
            <Route path={routes.payment.success.path} element={<PaymentSuccessPage />} />
            <Route path={routes.payment.failed.path} element={<PaymentFailedPage />} />
            <Route path={routes.welcome.index.path} element={<WelcomePage />} />
            <Route path={routes.payment.payment.path} element={<PaymentSuccessEmail />} />
            <Route path={routes.checkout.course.path} element={<Checkout />} />
            <Route path={routes.course.course.path} element={<Course />} />
            <Route path={routes.course.lesson.path} element={<LessonPage />} />
            <Route path={`${routes.settings.index.path}/*`} element={<SettingsPage />} />
            <Route path={routes.userHomePage.dashboard.path} element={<UserDashboardPage />} />
            {/* <Route path={routes.userHomePage.index.path} element={<UserHomePage />} /> */}
            <Route path="*" element={<Navigate to={routes.userHomePage.dashboard.path} />} />
            {/* <Route
            path="*"
            element={
              <Navigate
                to={`${routes.course.course.basePath!.replace(':courseId', 'main')}/overview`}
              />
            }
          /> */}
          </Routes>
        )}
      </Layout>
    </QueryClientProvider>
  );
}

export default App;
