import React, { ReactElement } from 'react';

// Import types
import { LinkSectionData } from 'common/types/RenderEngine.type';

// Import Component
import Header from 'components/Header/Header';
import SubHeader from 'components/SubHeader/SubHeader';
import Paragraph from 'components/Paragraph/Paragraph';
import { Button } from 'components/Button/index';

// Interfaces
export interface LinkSectionProps {
  data: LinkSectionData;
}

export default function LinkSection({
  data: { header, subHeader, paragraph, callToAction },
}: LinkSectionProps): ReactElement<LinkSectionProps> {
  // Handlers
  const handleCallToAction = () => {
    window.open(callToAction?.path, '_blank');
  };

  return (
    <div className="">
      <div className="mx-auto w-11/12 py-24 text-center md:w-1/2 xl:py-52">
        {subHeader && <SubHeader className="mb-5" title={subHeader} />}
        {header && <Header className="mb-5" title={header} />}
        {paragraph && <Paragraph className="mb-5" text={paragraph} />}
        {callToAction && (
          <Button
            background="green"
            color="white"
            title={callToAction.label}
            size="2xl"
            round="4xl"
            className=" mx-auto"
            onClick={handleCallToAction}
          />
        )}
      </div>
    </div>
  );
}
