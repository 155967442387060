import React from 'react';
import { useLocation } from 'react-router-dom';

export interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render: () => React.ReactNode | React.ReactElement | null;
  path: string;
}

const SettingsNavRouteWrapper = ({ path, render }: Props): React.ReactElement | null => {
  const location = useLocation();
  const isActive = location.pathname.includes(path);
  const activeClasses = 'opacity-100 h-auto';
  const inactiveClasses = 'opacity-0 h-0';
  return (
    <div
      className={`w-full overflow-hidden px-px transition-all lg:hidden ${
        isActive ? activeClasses : inactiveClasses
      }`}
    >
      {isActive ? render() : null}
    </div>
  );
};

export default SettingsNavRouteWrapper;
