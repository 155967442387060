import { ReactElement, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { loadStripe } from '@stripe/stripe-js';
// Import recoil atoms
import { userAtom } from 'store/userAtom';

// Import services
import paymentService from 'services/payment.service';

// Import types
import { StripeCheckoutSession } from 'common/types/StripeCheckoutSession.type';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line
export default function Checkout(): ReactElement {
  const navigate = useNavigate();
  // Effects
  useEffect(() => {
    createCheckoutSession();
  }, []);
  const { t } = useTranslation('pages');

  const { courseId } = useParams();
  const { user } = useRecoilValue(userAtom);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

  const createCheckoutSession = async () => {
    const session = await paymentService.newCheckoutSession(courseId!, user?.id || '');
    const checkoutSession: StripeCheckoutSession = JSON.parse(JSON.stringify(session));

    const sessionId = checkoutSession.id;
    const stripe = await stripePromise;
    const { error } = await stripe!.redirectToCheckout({
      sessionId,
    });

    if (error) {
      navigate('/payment/failed/' + courseId);
    }
  };

  return (
    <div className="relative flex h-full min-h-screen w-full flex-col items-center justify-center">
      <div className="absolute top-0 left-0 right-0 z-0 h-full w-full overflow-hidden">
        <video className="z-0 min-h-full min-w-full object-cover" loop autoPlay muted>
          <source src="/assets/video/checkout-bg-video.mp4" type="video/mp4" />
          {t('checkout.notSupported')}
        </video>
      </div>
      <div className="relative w-11/12 md:w-full md:max-w-2xl lg:max-w-3xl xl:max-w-5xl 2xl:max-w-7xl">
        <div className="relative z-10 flex flex-col items-center justify-center rounded-4xl bg-white bg-opacity-65 bg-cover px-8 py-10 shadow backdrop-blur backdrop-filter md:mx-auto ">
          <h1 className="mt-12 mb-6 text-center text-3xl font-medium text-primary md:text-5xl">
            {t('checkout.redirectedToPaymentSolution')}
          </h1>
          <h6 className="mt-6 mb-12 text-lg font-normal text-gray-secondary">
            {t('checkout.message')}
          </h6>
        </div>
      </div>
    </div>
  );
}
