import { ReactElement, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

// Import recoil atoms
import { userAtom } from 'store/userAtom';

// Import services
import paymentService from 'services/payment.service';

// Import Component
import PaymentMessage from 'components/Payment/PaymentMessage';
import { useTranslation } from 'react-i18next';

const PaymentSuccess = (): ReactElement => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // Effects
  useEffect(() => {
    confirmSubscription();
  }, []);

  const { t } = useTranslation('pages');
  const { user } = useRecoilValue(userAtom);
  const programId = searchParams.get('program_id');
  const sessionId = searchParams.get('session_id');

  const confirmSubscription = async () => {
    const result = await paymentService.confirmSubscription(sessionId!, programId!, user?.id || '');
    if (result !== 'succeeded') navigate('/payment/failed/' + programId);
  };

  return (
    <PaymentMessage
      title={t('payment.successPage.title')}
      message={t('payment.successPage.message')}
      additionalMessage={t('payment.successPage.additionalMessage')}
      isSuccess={true}
      button={{
        title: t('payment.successPage.backToMTG'),
        onClick: () => navigate('/course/' + programId + '/overview'),
      }}
    />
  );
};

export default PaymentSuccess;
