import React, { ReactElement } from 'react';
import SubHeader from 'components/SubHeader/SubHeader';
import Header from 'components/Header/Header';
import Paragraph from 'components/Paragraph/Paragraph';
import { Button } from 'components/Button';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import { Image } from 'components/Image';

// Import types
import { MultimediaSectionData, MediaTypeEnum } from 'common/types/RenderEngine.type';

export interface MultimediaSectionProps {
  data: MultimediaSectionData;
}

export default function MultimediaSection({
  data: { header, subHeader, paragraph, callToAction, media, backgroundColor = '' },
}: MultimediaSectionProps): ReactElement {
  // Handlers
  const handleCallToAction = () => {
    if (!callToAction) return null;
    window.open(callToAction.path, '_blank');
  };
  return (
    <div
      style={{ ...(backgroundColor && { backgroundColor }) }}
      className="w-full bg-white-900 py-16 lg:py-40"
    >
      <div className="w-full px-4 lg:px-36 ">
        <div className="mx-auto hidden w-full flex-col gap-10 lg:flex lg:flex-row lg:gap-20 ">
          {media && (
            <div className="relative w-96 flex-1 overflow-hidden pb-10 lg:pb-0">
              {media.type === MediaTypeEnum.VIDEO ? (
                <VideoPlayer
                  data={{
                    path: media?.video?.path || '',
                    poster: {
                      path: media?.video?.poster.path || '',
                    },
                  }}
                  className="testtt aspect-w-16 aspect-h-9 rounded-2xl.5"
                />
              ) : null}

              {media.type === MediaTypeEnum.IMAGE ? (
                <Image
                  className="rounded-2xl.5"
                  src={media.image?.path || ''}
                  alt={media.image?.title || ''}
                />
              ) : null}
            </div>
          )}
          <div className="flex w-1/2 flex-1 flex-col justify-center">
            {subHeader && <SubHeader className="mb-5" title={subHeader} />}
            {header && <Header className="mb-10" title={header} />}
            {paragraph && <Paragraph className="mb-10 leading-8" text={paragraph} />}
            {callToAction && (
              <Button
                background="green"
                color="white"
                size="2xl"
                round="4xl"
                title={callToAction.label}
                onClick={handleCallToAction}
              />
            )}
          </div>
        </div>
        <div className="mx-auto w-11/12 flex-col lg:hidden   lg:flex-row">
          {subHeader && <SubHeader className="mb-3 text-center" title={subHeader} />}
          {header && <Header className="mb-5 text-center" title={header} />}
          {media && (
            <div className="relative flex-1 overflow-hidden pb-10 lg:pb-0 lg:pr-10">
              {media.type === MediaTypeEnum.VIDEO ? (
                <VideoPlayer
                  data={{
                    path: media?.video?.path || '',
                    poster: {
                      path: media?.video?.poster.path || '',
                    },
                  }}
                  className="aspect-w-16 aspect-h-9 rounded-2xl.5"
                />
              ) : null}

              {media.type === MediaTypeEnum.IMAGE ? (
                <Image
                  className="rounded-2xl.5"
                  src={media.image?.path || ''}
                  alt={media.image?.title || ''}
                />
              ) : null}
            </div>
          )}

          {paragraph && <Paragraph className="mb-10" text={paragraph} />}
          {callToAction && (
            <Button
              className="mx-auto"
              background="green"
              color="white"
              size="2xl"
              round="4xl"
              title={callToAction.label}
              onClick={handleCallToAction}
            />
          )}
        </div>
      </div>
    </div>
  );
}
