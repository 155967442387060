/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { AxiosError } from 'axios';

// Types
import { useQuery } from 'react-query';

// Services
import freeContentService from 'services/freeContent.service';
import courseService from 'services/course.service';

// Components
import { Alert } from 'components/Alert';
import { AbosluteSpinner } from 'components/Spinners';
import FreeContentPlaylist from 'components/FreeContent/FreeContentPlaylist';
import CourseCard from 'components/Course/CourseCard';
import { PageContent } from 'components/Page';
import Header from 'components/Header/Header';

const FreeContent = (): ReactElement => {
  // Hooks
  const params = useParams();
  const { freeContentId = '' } = params as { freeContentId: string };
  const navigate = useNavigate();

  const {
    data: notEnrolledCourses,
    isLoading: isLoadingNotEnrolledCourses,
    error: noteEnrolledError,
  } = useQuery('note_enrolled_curses', () =>
    courseService.getCourses({ enrolled: 'NOT_ENROLLED' })
  );

  const {
    data: freeContent,
    isLoading: isLoadingFreeContent,
    error: freeContentError,
  } = useQuery('free_content', freeContentService.getFreeContent);

  const {
    data: freeContentItem,
    isLoading: isLoadingFreeContentItem,
    error: freeContentItemError,
    refetch: refetchFreeContentItem,
  } = useQuery('free_content_item', () => freeContentService.getFreeContentItem(freeContentId), {
    enabled: !!freeContentId,
  });

  const handleOnItemClick = (id: string) => {
    navigate(`/content/${id}`);
  };

  const { pathname } = useLocation();
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  useEffect(() => {
    refetchFreeContentItem();
  }, [freeContentId]);

  const errors = [
    (noteEnrolledError as AxiosError)?.message || '',
    (freeContentError as AxiosError)?.message || '',
    (freeContentItemError as AxiosError)?.message || '',
  ];
  const isLoading = isLoadingNotEnrolledCourses || isLoadingFreeContent || isLoadingFreeContentItem;

  return (
    <div className="relative z-40 flex-grow">
      <AbosluteSpinner show={isLoading} />
      {errors.map((error, index) => (
        <Alert message={error} key={`${error}_${index}`} />
      ))}
      {freeContent?.items.length && freeContentItem && freeContent && (
        <FreeContentPlaylist
          videoClick={handleOnItemClick}
          activeItem={freeContentItem}
          items={freeContent.items}
        />
      )}
      <PageContent wrapperClassName="bg-white pt-8">
        {!!notEnrolledCourses?.items.length && (
          <div className="flex flex-col gap-5">
            <Header fontWeight="font-bold" className="text-4xl" title="Recommendations" />
            {notEnrolledCourses && (
              <div className="carousel editedCarousel p-px transition-all">
                {notEnrolledCourses.items.map((item) => {
                  return (
                    <CourseCard
                      coursePath={`/course/${item.id}/overview`}
                      key={`${item.title}_${item.id}`}
                      title={item.title}
                      description={item.description}
                      imagePath={item.overview?.thumbnailUrl}
                      price={item.program.price}
                    />
                  );
                })}
              </div>
            )}
          </div>
        )}
      </PageContent>
    </div>
  );
};

export default FreeContent;
