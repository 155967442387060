import { ReactElement } from 'react';

// Svg imports
import { ReactComponent as DashboardIcon } from 'assets/svg-icons/dashboard-icon.svg';
import { ReactComponent as UserIcon } from 'assets/svg-icons/user-icon.svg';
import { ReactComponent as SettingsIcon } from 'assets/svg-icons/settings-icon.svg';
import { ReactComponent as ListIcon } from 'assets/svg-icons/list-icon.svg';
import { ReactComponent as EyeIcon } from 'assets/svg-icons/eye-icon.svg';
import { ReactComponent as EyeCloseIcon } from 'assets/svg-icons/eye-close-icon.svg';
import { ReactComponent as ContentPlayButton } from 'assets/svg-icons/content-play-button.svg';

// Types
export enum SvgIconTypes {
  DEFAUTL,
  NAVIGATION,
}

export enum SvgIconList {
  DASHBOARD,
  USER,
  SETTINGS,
  LIST,
  EYE,
  EYECLOSE,
  CONTENT_PLAY_BUTTON,
}

// Dashboard icon
const svgIcons = {
  [SvgIconList.DASHBOARD]: DashboardIcon,
  [SvgIconList.USER]: UserIcon,
  [SvgIconList.SETTINGS]: SettingsIcon,
  [SvgIconList.LIST]: ListIcon,
  [SvgIconList.EYE]: EyeIcon,
  [SvgIconList.EYECLOSE]: EyeCloseIcon,
  [SvgIconList.CONTENT_PLAY_BUTTON]: ContentPlayButton,
};

interface Props {
  name: SvgIconList;
  type?: SvgIconTypes;
  width?: number | string;
  height?: number | string;
  className?: string;
  svgClassName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any;
}

export function SvgIcon({
  name,
  type = SvgIconTypes.DEFAUTL,
  width = 19,
  height = 19,
  className = '',
  svgClassName = '',
  style = {},
}: Props): ReactElement {
  const Icon = svgIcons[name];

  if (type === SvgIconTypes.NAVIGATION) {
    width = 24;
    height = 24;
  }

  return (
    <div className={className}>
      <Icon style={style} className={svgClassName} width={width} height={height} />
    </div>
  );
}

export default SvgIcon;
