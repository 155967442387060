import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// Import types
import { ResetPasswordRequest } from 'common/types/ResetPassword.type';

// Import services
import authService from 'services/auth.service';

// Import components
import { Input } from 'components/Form/Input';
import { Alert } from 'components/Alert';
import { Button } from 'components/Button/index';
import { useTranslation } from 'react-i18next';

const formSchema = yup.object().shape({
  email: yup.string().email('Vennligst skriv inn en gyldig e-postadresse').required('Epost kreves'),
});

const ResetPassword: FC = () => {
  const { t } = useTranslation('pages');
  // State
  const [loading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // FOrm
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordRequest>({ resolver: yupResolver(formSchema) });

  // Handlers
  const handleClose = () => {
    navigate('/auth/sign-in?passwordChanged=true');
  };

  const onSubmit = async (data: Record<string, string>) => {
    authService
      .resetPassword(data.email)
      .then((response) => {
        handleClose();
      })
      .catch((error) => {
        setError(error.responseMessage);
      });
  };

  return (
    <div className="relative">
      <img src="/mtg-logo.png" className="mx-auto" alt="MTG Logo" />
      <div className="z-10 mx-auto my-5 w-11/12 rounded-4xl bg-white bg-opacity-75 bg-cover px-8 py-10 shadow backdrop-blur backdrop-filter md:mx-auto md:w-460">
        <Alert message={error} />
        <p
          aria-label={t('resetPassword.title')!}
          className="mb-5 text-center text-2xl font-bold leading-6 text-gray-800 "
        >
          {t('resetPassword.title')}
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full ">
            <Input
              autoComplete="email"
              type="email"
              {...register('email')}
              placeholder={t('resetPassword.email')}
              errors={errors}
            />
          </div>
          <div className="mt-5">
            <Button
              disabled={loading}
              background="green"
              color="white"
              type="submit"
              title={t('resetPassword.resetPassword')!}
              full={true}
              round="4xl"
              size="xl"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
