import { ReactElement, forwardRef, ForwardedRef } from 'react';

// Import Type
export interface CheckboxProps {
  checked?: boolean;
  label: string;
  link?: string;
  textLink?: string;
  afterTextLink?: string;
  className?: string;
  labelClassNames?: string;
  inputClassNames?: string;
  name: string;
}

export type InputPropsType = Omit<React.HTMLProps<HTMLInputElement>, 'ref'> & CheckboxProps;

const Checkbox = (
  {
    checked = false,
    label,
    link,
    textLink,
    afterTextLink,
    className = '',
    labelClassNames = '',
    inputClassNames = '',
    name,
    disabled = false,
    ...props
  }: InputPropsType,
  ref: ForwardedRef<HTMLInputElement>
): ReactElement => {
  return (
    <div className={`flex items-center ${disabled ? 'opacity-60' : ''} ${className}`}>
      <div className="relative mr-3 h-8 w-8 flex-form__checkbox rounded-md border border-inputColor bg-white">
        <label
          htmlFor={name}
          className={`relative block h-full w-full rounded-md bg-white ${labelClassNames}`}
        >
          <input
            id={name}
            name={name}
            type="checkbox"
            className={`
              outline-none absolute left-0 right-0 bottom-0 top-0 ml-1 h-full w-full border-0 bg-transparent opacity-0 checked:h-10 checked:w-10 checked:bg-nike-icon
              ${inputClassNames}
            `}
            ref={ref}
            checked={checked}
            disabled={disabled}
            {...props}
          />
          {checked && (
            <span
              className={`absolute left-0 bottom-0 right-0 top-0 translate-x-1 transform ${
                checked ? 'bg-nike-icon' : 'bg-delete-icon'
              } h-7 w-9 bg-no-repeat `}
            ></span>
          )}
          {!checked && (
            <span
              className={`absolute left-px top-1 translate-x-1 transform ${
                !checked ? '' : 'bg-nike-icon'
              } h-7 w-9 bg-no-repeat `}
            ></span>
          )}
        </label>
      </div>
      <label htmlFor={name} className="w-full pl-2 text-left text-lg font-medium text-primary">
        {label}{' '}
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className="text-center text-lg font-bold text-secondary"
        >
          {textLink}
        </a>
        {afterTextLink}
      </label>
    </div>
  );
};

export default forwardRef(Checkbox);
