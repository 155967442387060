import { ReactElement } from 'react';

// Components
import { FreeContentType } from 'common/types/FreeContent.type';
import { Image } from 'components/Image';

interface Props {
  onItemClick: (id: string) => void;
  items: FreeContentType[];
  activeItemId: string;
}

const FreeContentsItems = ({
  items,
  onItemClick: videoClick,
  activeItemId,
}: Props): ReactElement => {
  return (
    <>
      {items.map(
        ({
          title,
          id,
          summary,
          content: {
            data: { posterImage, posterImageTitle },
          },
        }) => (
          <div
            id={id}
            className={`flex max-w-full cursor-pointer gap-2 rounded-2xl  bg-opacity-10 p-1 hover:bg-white hover:bg-opacity-20  ${
              activeItemId === id ? 'bg-white' : ''
            }`}
            onClick={() => activeItemId !== id && videoClick(id)}
            key={`${title}_${id}`}
          >
            <div className="aspect-w-1 h-32 w-4/12 overflow-hidden rounded-2xl">
              <Image src={posterImage} alt={posterImageTitle} />
            </div>

            <div className="w-8/12">
              <p className="text-base font-bold text-white">{title}</p>
              <p className="max-h-24 overflow-hidden text-ellipsis text-sm text-white">{summary}</p>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default FreeContentsItems;
