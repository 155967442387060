import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const TabLinks = (): ReactElement => {
  const { t } = useTranslation('components');
  const location = useLocation();

  // const isCourses = location.pathname.includes('home');
  const isDashboard = location.pathname === '/';
  const isMyProfile = location.pathname.includes('settings');

  return (
    <div className="flex w-full justify-center gap-5 bg-black-100 px-5 py-10 md:justify-start lg:px-36">
      {/* <Link
        to={'/home'}
        className={`
              relative cursor-pointer px-5 pb-3 text-center text-xl text-white md:px-10
              ${!isCourses ? 'font-normal opacity-50' : 'font-medium'}
            `}
      >
        {t('courses.courses')}
        {isCourses && (
          <div className="green-gradient absolute left-0 bottom-0 h-1 w-full translate-y-full transform rounded md:h-1.5 " />
        )}
      </Link> */}
      <Link
        to={'/'}
        className={`
          relative cursor-pointer px-5 pb-3 text-center text-xl text-white md:px-10
          ${!isDashboard ? 'font-normal opacity-50' : 'font-medium'}
        `}
      >
        {t('courses.dashboard')}
        {isDashboard && (
          <div className="green-gradient absolute left-0 bottom-0 h-1 w-full translate-y-full transform rounded md:h-1.5 " />
        )}
      </Link>
      <Link
        to={'/settings/profile'}
        className={`
          relative cursor-pointer whitespace-nowrap px-5 pb-3 text-center text-xl text-white md:px-10
          ${!isMyProfile ? 'font-normal opacity-50' : 'font-medium'}
        `}
      >
        {t('courses.myProfile')}
        {isMyProfile && (
          <div className="green-gradient absolute left-0 bottom-0 h-1 w-full translate-y-full transform rounded md:h-1.5 " />
        )}
      </Link>
    </div>
  );
};

export default TabLinks;
