import { ReactElement } from 'react';

// Import Type
import { CourseOverviewType } from 'common/types/Course.type';

// Import utils
import { formatCentsToDecimal } from 'utils/priceUtils';

// Import component
import { Image } from 'components/Image';
import Header from 'components/Header/Header';
import Paragraph from 'components/Paragraph/Paragraph';
import { Button } from 'components/Button';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import SubHeader from 'components/SubHeader/SubHeader';
import { useNavigate, useParams } from 'react-router-dom';

import programService from 'services/program.service';

export interface CourseHeaderProp {
  data: CourseOverviewType;
}

export default function CourseHeader({
  data: {
    price,
    logo,
    header,
    description,
    callToAction,
    author,
    media: { type, image, video },
    studentIsEnrolled,
  },
}: CourseHeaderProp): ReactElement<CourseHeaderProp> {
  const navigate = useNavigate();
  const { courseId } = useParams();

  // Handlers
  const handleCallToAction = async () => {
    const program = await programService.getProgramByCourseId(courseId || '');
    navigate(`/checkout/${program.id}`);
  };

  return (
    <div className="relative z-40 h-auto w-full pb-10 pt-36 text-center lg:text-left">
      <div className="absolute top-0 bottom-0 left-0 right-0 z-0 h-full w-full overflow-hidden">
        <Image
          className="h-full w-full "
          src="/assets/img/course-header.jpg"
          alt="Course Overview"
        />
      </div>
      <div className="testClass relative mx-auto flex h-full w-11/12 flex-col items-center justify-between md:w-10/12 lg:flex-row">
        <div className="flex-1 lg:py-0">
          <div className="w-full lg:mr-auto lg:w-11/12">
            {logo?.path && <Image className="mb-10" src={logo.path} alt={logo.title || 'logo'} />}
            <Header className="mb-10" isWhite title={header} />
            <Paragraph className="mb-10" isWhite text={description} />
            <div className="mb-10 flex flex-row flex-nowrap items-center">
              {author?.image?.path && (
                <div className="w-1/4 pr-5">
                  <div className="w-full">
                    <div className="aspect-w-1 aspect-h-1">
                      <Image
                        className="w-full object-cover"
                        src={author.image?.path || ''}
                        alt={author.image?.title || 'Avatar'}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="flex-1 text-left">
                <SubHeader
                  className="mb-4 text-2xl leading-3 md:text-3xl"
                  isWhite
                  fontWeight="font-bold"
                  title={author.name}
                />
                <Paragraph fontWeight="font-normal" className="-mt-2" isWhite text={author.title} />
              </div>
            </div>
            <div className="hidden w-fit lg:block">
              <Button
                background="green"
                color="white"
                title={callToAction.label}
                size="2xl"
                round="4xl"
                className="mb-5"
                onClick={handleCallToAction}
                disabled={studentIsEnrolled}
              />
              <div className="flex justify-center gap-2">
                <h1 className="text-xl font-bold text-white">&#36;</h1>
                <SubHeader
                  fontWeight="font-medium"
                  className="text-center"
                  isWhite
                  title={`${formatCentsToDecimal(price || 0)}`}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex-1 pb-10">
          <div className="relative w-full p-2 lg:ml-auto lg:w-11/12 lg:p-0">
            {/* Desktop leafes */}
            <img
              className="absolute top-0 left-0 z-20 hidden -translate-x-1/2 -translate-y-1/3 scale-50 transform object-cover md:translate-x-0 md:-translate-y-1/2 lg:flex"
              src="/assets/img/leaf-topleft.svg"
              alt="leafes"
            />
            <img
              className="absolute right-0 top-1/2 bottom-1/2 z-0 hidden translate-x-16 -translate-y-1/2 scale-50 transform object-cover md:translate-x-4/7 md:-translate-y-1/2 lg:flex"
              src="/assets/img/leaf-topright.svg"
              alt="leafes"
            />
            <img
              className="absolute bottom-0 left-0 z-20 hidden translate-y-1/2 translate-x-3/4 scale-50 transform object-cover lg:flex"
              src="/assets/img/leaf-botleft.svg"
              alt="leafes"
            />

            {/* Mobile leafes */}
            <img
              className="absolute -right-4 -top-6 z-20 w-20 lg:hidden"
              src="/assets/img/leaf-topright.svg"
              alt="leafes"
            />
            <img
              className="absolute -left-2 -bottom-2 z-20 w-10 lg:hidden"
              src="/assets/img/leaf-botleft.svg"
              alt="leafes"
            />

            {type === 'VideoPlayerType' && video ? (
              <div className="aspect-w-10 aspect-h-7">
                <VideoPlayer className="w-full rounded-3xl object-cover" data={{ ...video }} />
              </div>
            ) : null}
            {type === 'ImageType' && image ? (
              <div className="aspect-w-10 aspect-h-7">
                {image.path && <Image src={image.path} alt={image.title || 'poster'} />}
              </div>
            ) : null}
          </div>
        </div>

        <div className="w-fit lg:hidden">
          <Button
            background="green"
            color="white"
            title={callToAction.label}
            size="2xl"
            round="4xl"
            className="mb-5"
            onClick={handleCallToAction}
            disabled={studentIsEnrolled}
          />
          <div className="flex justify-center gap-2">
            <h1 className="text-xl font-bold text-white">&#36;</h1>
            <SubHeader
              fontWeight="font-medium"
              className="text-center"
              isWhite
              title={`${price}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
