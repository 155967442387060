import { FreeContentType } from 'common/types/FreeContent.type';
import { ListResult } from 'common/types/ListResult.type';
import api from 'utils/api';

class FreeContentService {
  async getFreeContent() {
    const freeContents: ListResult<FreeContentType> = await api.get('/api/free-content');
    return freeContents;
  }
  async getFreeContentItem(freeContentId: string) {
    const freeContents: FreeContentType = await api.get(`/api/free-content/${freeContentId}`);
    return freeContents;
  }
}
export default new FreeContentService();
