import { ReactElement } from 'react';
// Import component
import Header from 'components/Header/Header';
import SubHeader from 'components/SubHeader/SubHeader';
import { Image } from 'components/Image';

// Import Type
import { QuoteSectionData } from 'common/types/RenderEngine.type';
export interface QuoteSectionProp {
  data: QuoteSectionData;
}
export default function QuotesSection({
  data: { header, subHeader, author, image },
}: QuoteSectionProp): ReactElement<QuoteSectionProp> {
  const isWhite = !!image;
  return (
    // eslint-disable-next-line prettier/prettier
    <div
      className={`relative w-full overflow-hidden ${
        image ? ' md:aspect-w-7 md:aspect-h-3' : ''
      } h-auto`}
    >
      <div className="relative z-30 min-h-400 w-11/12 py-8 pl-4 md:min-h-300 md:w-10/12 md:py-36 md:pl-36">
        {subHeader && <SubHeader className="mb-10 md:mb-5" isWhite={isWhite} title={subHeader} />}
        {header && (
          <Header
            className="mb-20 text-3xl leading-tight md:mb-5 md:text-5xl.5"
            isWhite={isWhite}
            title={header}
          />
        )}
        {author && <SubHeader isWhite={isWhite} title={author} />}
      </div>
      {image && (
        <div className="absolute top-0 left-0 right-0 bottom-0 z-10 h-full w-full">
          <div className="aspect-w-3 aspect-h-4 relative overflow-hidden md:aspect-w-7 md:aspect-h-3">
            <Image className="w-full object-fill" src={image.path} alt={image.title || ''} />
            <div className="absolute top-0 bottom-0 left-0 right-0 bg-black opacity-50" />
          </div>
        </div>
      )}
      {image && (
        <div className="absolute top-0 left-0 right-0 bottom-0 z-20 h-full w-full bg-black-100 bg-opacity-50" />
      )}
    </div>
  );
}
