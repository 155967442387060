import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Link, useLocation } from 'react-router-dom';

const SignupLoginLinks = (): ReactElement => {
  const { t } = useTranslation('pages');
  const location = useLocation();
  return (
    <div className="relative flex flex-col items-center justify-center ">
      <div className="z-10 mx-auto mb-5 flex w-full flex-row justify-around">
        <Link className="relative flex-basis-1/3 py-2 text-center text-white" to="/auth/sign-up">
          {t('signin.register')}
          {location.pathname === '/auth/sign-up' && (
            <div className="absolute bottom-0 h-1 w-full rounded-md bg-white"></div>
          )}
        </Link>
        <Link className="relative flex-basis-1/3 py-2 text-center text-white" to="/auth/sign-in">
          {location.pathname === '/auth/sign-in' && (
            <div className="absolute bottom-0 h-1 w-full rounded-md bg-white"></div>
          )}
          {t('signin.signin')}
        </Link>
      </div>
    </div>
  );
};
export default SignupLoginLinks;
