import { Image } from 'components/Image';
import { ReactElement } from 'react';

// Import utils
import { formatCentsToDecimal } from 'utils/priceUtils';

// Import Components
// import { ReactComponent as StarsIcon } from 'assets/svg-icons/stars-icon.svg';
import { ReactComponent as NewNikeIcon } from 'assets/svg-icons/new-nike-icon.svg';
import { ReactComponent as SponsoredStarIcon } from 'assets/svg-icons/sponsored-star-icon.svg';
import { Link } from 'react-router-dom';

interface BadgeType {
  text: string;
  icon: 'sponsored' | 'new';
  color: 'light-blue-gradient' | 'light-yellow-gradient';
}
interface Props {
  coursePath: string;
  title: string;
  description: string;
  price?: number | null;
  imagePath?: string;
  badge?: BadgeType;
}

const CourseCard = ({
  coursePath,
  title,
  description,
  price = null,
  imagePath = '/assets/img/default-image.jpg',
  badge,
}: Props): ReactElement => {
  return (
    <Link to={coursePath}>
      <div className="relative flex h-full min-w-360 max-w-520 flex-none flex-col overflow-hidden rounded-xl shadow">
        {badge && (
          <div className={`absolute top-0 right-0 z-10 rounded-bl-xl px-4 py-2  ${badge.color}`}>
            <h1 className="flex flex-nowrap items-center gap-2 text-lg font-bold text-white">
              {badge.icon === 'new' ? <NewNikeIcon /> : <SponsoredStarIcon />}
              {badge.text}
            </h1>
          </div>
        )}
        <div className="aspect-w-3 aspect-h-1 max-h-52 flex-grow">
          <Image src={imagePath} alt="Course" />
        </div>
        <div className="flex flex-1 flex-grow flex-col gap-5 p-5">
          <div className="flex flex-col gap-2">
            <h1 className=" truncate text-xl font-bold text-primary">{title}</h1>
            <p className=" h-15 overflow-hidden overflow-ellipsis text-sm text-gray-500">
              {description}
            </p>
          </div>
          <div className="flex items-center justify-between">
            <div className="">{/* <StarsIcon /> */}</div>
            <h3 className="text-26 font-bold text-primary">$ {formatCentsToDecimal(price || 0)}</h3>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CourseCard;
