import { ReactElement } from 'react';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

// Services
import courseService from 'services/course.service';
import freeContentService from 'services/freeContent.service';
import contentService from 'services/content.service';

// Components
import AbsoluteSpinner from 'components/Spinners/AbsoluteSpinner';
import CourseCard from 'components/Course/CourseCard';
import Header from 'components/Header/Header';
// import CourseCardProgress from 'components/Course/CourseCardProgress';
import { TabLinks } from 'components/TabLinks';
import { Alert } from 'components/Alert';
// import { MembershipBanner } from 'components/MembershipBanner';
// import FilterCourses from 'containers/FilterCourses';
import { FreeContentCard } from 'components/Card';
import RowCard from 'components/Course/RowCard';
import { PageContent } from 'components/Page';
import blogService from 'services/blog.service';
import { FeaturedContentSlider } from 'components/Sliders';

const UserDashboardPage = (): ReactElement => {
  const {
    data: featuredContent,
    isLoading: isLoadingFeaturedContent,
    error: featuredContentError,
  } = useQuery('featured_content', () => contentService.getFeaturedContent());

  const {
    data: enrolledCourses,
    isLoading: isLoadingEnrolledCourses,
    error: enrolledError,
  } = useQuery('enrolled_courses', () => courseService.getCourses({ enrolled: 'ENROLLED' }));

  const { data: blogPosts, error: blogPostsError } = useQuery('blog_posts', () =>
    blogService.getBlogPosts()
  );
  const {
    data: notEnrolledCourses,
    isLoading: isLoadingNotEnrolledCourses,
    error: noteEnrolledError,
  } = useQuery('note_enrolled_curses', () =>
    courseService.getCourses({ enrolled: 'NOT_ENROLLED' })
  );

  const {
    data: freeContent,
    isLoading: isLoadingFreeContent,
    error: freeContentError,
  } = useQuery('free_content', freeContentService.getFreeContent);

  // Handlers
  // const playlistScrollToActiveItem = () => {
  //   if (!activeItem.id) return;
  //   const itemElement = document.getElementById(activeItem.id);
  //   itemElement?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
  // };

  const errors = [
    (enrolledError as AxiosError)?.message || '',
    (noteEnrolledError as AxiosError)?.message || '',
    (freeContentError as AxiosError)?.message || '',
    (blogPostsError as AxiosError)?.message || '',
    (featuredContentError as AxiosError)?.message || '',
  ];

  return (
    <div className="bg-white">
      <TabLinks />
      <AbsoluteSpinner
        show={
          isLoadingEnrolledCourses ||
          isLoadingNotEnrolledCourses ||
          isLoadingFreeContent ||
          isLoadingFeaturedContent
        }
      />
      {errors.map((error) => (
        <Alert message={error} />
      ))}

      <PageContent wrapperClassName="bg-white">
        <FeaturedContentSlider items={featuredContent?.items || []} />
        {!!enrolledCourses?.items.length && (
          <div className="flex flex-col gap-5">
            <Header fontWeight="font-bold" title="My courses" />
            {enrolledCourses && (
              <div className="carousel editedCarousel p-px transition-all">
                {enrolledCourses.items.map((item) => {
                  return (
                    <CourseCard
                      coursePath={`/course/${item.id}/overview`}
                      key={`${item.title}_${item.id}`}
                      title={item.title}
                      description={item.description}
                      imagePath={item.overview?.thumbnailUrl}
                      price={item.program.price}
                    />
                  );
                })}
              </div>
            )}
          </div>
        )}

        {/* <MembershipBanner /> */}
        {!!notEnrolledCourses?.items.length && (
          <div className="flex flex-col gap-5">
            <Header fontWeight="font-bold" className="text-4xl" title="Recommendations" />
            {notEnrolledCourses && (
              <div className="carousel editedCarousel p-px transition-all">
                {notEnrolledCourses.items.map((item) => {
                  return (
                    <CourseCard
                      coursePath={`/course/${item.id}/overview`}
                      // badge={{ color: 'light-yellow-gradient', text: 'Sponsored', icon: 'sponsored' }}
                      key={`${item.title}_${item.id}`}
                      title={item.title}
                      description={item.description}
                      imagePath={item.overview?.thumbnailUrl}
                      price={item.program.price}
                    />
                  );
                })}
              </div>
            )}
          </div>
        )}

        {!!freeContent?.items.length && (
          <div className="flex flex-col gap-5">
            <Header fontWeight="font-bold" className="text-4xl" title="Free video content" />
            <div className="carousel flex gap-5">
              {freeContent?.items.map((item) => (
                <FreeContentCard content={item} />
              ))}
            </div>
          </div>
        )}

        {!!blogPosts?.items.length && (
          <div className="flex flex-col gap-5">
            <Header fontWeight="font-bold" className="text-4xl" title="Free articles & posts" />
            <div className="carousel flex gap-5">
              {blogPosts.items.map((item) => {
                return (
                  <RowCard
                    key={item.id}
                    contentUrl={item.contentUrl}
                    imagePath={item.imagePath}
                    title={item.title}
                    description={item.description}
                  />
                );
              })}
            </div>
          </div>
        )}
      </PageContent>
    </div>
  );
};

export default UserDashboardPage;
