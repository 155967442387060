import AuthLayout from 'layouts/AuthLayout';
import MainLayout from 'layouts/MainLayout';
import { useLocation } from 'react-router-dom';

// eslint-disable-next-line
const Layout = (props: any) => {
  const location = useLocation();
  const layout = location.pathname.split('/')[1] || 'default';

  if (
    layout === 'auth' ||
    layout === 'reset-password' ||
    layout === 'checkout' ||
    layout === 'payment' ||
    layout === 'welcome'
  ) {
    return <AuthLayout {...props} />;
  }

  return <MainLayout {...props} />;
};
export default Layout;
