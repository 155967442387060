import React, { ReactElement, ReactNode } from 'react';

// Interfaces
export interface ParagraphProps {
  isWhite?: boolean;
  className?: string;
  text?: string | ReactNode;
  children?: ReactNode | ReactElement | string;
  fontWeight?:
    | 'font-thin'
    | 'font-light'
    | 'font-normal'
    | 'font-medium'
    | 'font-bold'
    | 'font-semibold';
  textAlign?: 'left' | 'center' | 'right';
}

export default function Paragraph({
  isWhite = false,
  className,
  text = 'left',
  children,
  fontWeight = 'font-medium',
  textAlign = 'left',
}: ParagraphProps): ReactElement {
  return (
    <p
      className={`whitespace-pre-line text-base leading-6 md:text-2xl md:leading-8 ${fontWeight}
        ${className ? className : ''}
        ${isWhite ? 'text-white' : 'text-gray-500'}
        text-${textAlign}
      `}
    >
      {children}
      {text}
    </p>
  );
}
