import Header from 'components/Header/Header';
import Paragraph from 'components/Paragraph/Paragraph';
import SubHeader from 'components/SubHeader/SubHeader';
import React, { ReactElement, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userAtom } from 'store/userAtom';

// Import services
import subscriptionService from 'services/subscription.service';

import { Subscription } from '../../common/types/Subscription.type';

import momentDateFormater from 'moment';

// Import types
import { useTranslation } from 'react-i18next';

type Props = {
  className?: string;
};

export default function PaymentPage({ className }: Props): ReactElement {
  const { t } = useTranslation('pages');
  const [subscriptions, setSubscriptions] = useState({} as Subscription[]);

  // Effects
  useEffect(() => {
    getStudentSubscriptions();
  }, []);

  const { user } = useRecoilValue(userAtom);

  const getStudentSubscriptions = async () => {
    const subscriptionsResult = await subscriptionService.getAllSubscriptions(user?.id as string);
    setSubscriptions(subscriptionsResult);
  };

  const renderSubscriptions = () => {
    if (Array.isArray(subscriptions)) {
      return subscriptions.map((subscription) => (
        <tr className="">
          <td className="w-1/6 py-3">
            <span className="lg:text-l inline-flex w-40 text-lg text-gray-700 md:w-fit">
              {momentDateFormater(subscription.fromDate).format('DD/MM/YYYY')}
            </span>
          </td>
          <td className="w-1/6 py-3">
            <span className="lg:text-l inline-flex w-40 text-lg text-gray-700 md:w-fit">
              {subscription.program && subscription.program.title}
            </span>
          </td>
          <td className="w-1/6 py-3">
            <span className="lg:text-l inline-flex w-40 text-lg text-gray-700 md:w-fit">
              {(subscription.program.price / 100).toLocaleString('no-NO', {
                style: 'currency',
                currency: 'NOK',
                minimumFractionDigits: 0,
              })}
            </span>
          </td>
          <td className="w-2/6 py-3">
            <span className="lg:text-l inline-flex w-40 text-lg text-gray-700 md:w-fit">
              {subscription.receiptId}
            </span>
          </td>
        </tr>
      ));
    }
  };

  momentDateFormater.locale('en');

  if (subscriptions === null)
    return (
      <div className={className}>
        <Header className="mb-5" title={t('settings.paymentPage.invoicesAndPayments')} />
        <div className="rounded-4xl bg-transparent lg:bg-white lg:p-7 lg:shadow-md ">
          <SubHeader
            title={t('settings.paymentPage.invoicesAndReceipts')!}
            className="text-2xl text-primary md:text-3xl"
          />
          <Paragraph
            text={t('settings.paymentPage.seeYourBilling')}
            className="my-5 text-base text-gray-700"
          />
          <div className="custom-scroll overflow-x-auto">
            <table className="custom-scroll w-auto table-fixed overflow-x-auto lg:w-full">
              <thead>
                <tr className="">
                  <th className="w-fit text-left text-lg font-medium text-primary lg:w-1/5 lg:text-xl">
                    {t('settings.paymentPage.date')}
                  </th>
                  <th className="w-fit text-left text-lg font-medium text-primary lg:w-1/5 lg:text-xl">
                    {t('settings.paymentPage.course')}
                  </th>
                  <th className="w-fit text-left text-lg font-medium text-primary lg:w-1/5 lg:text-xl">
                    {t('settings.paymentPage.price')}
                  </th>
                  <th className="w-fit text-left text-lg font-medium text-primary lg:w-1/5 lg:text-xl">
                    {t('settings.paymentPage.order')} #
                  </th>
                  <th className="w-fit text-left text-lg font-medium text-primary lg:w-1/5 lg:text-xl"></th>
                </tr>
              </thead>
            </table>
            <Paragraph
              text={t('settings.paymentPage.youHaveNoPayment')}
              className="my-5 text-center text-base text-gray-700"
            />
          </div>
        </div>
      </div>
    );
  else
    return (
      <div className={className}>
        <Header className="mb-5" title={t('settings.paymentPage.invoicesAndPayments')} />
        <div className="rounded-4xl bg-transparent lg:bg-white lg:p-7 lg:shadow-md ">
          <SubHeader
            title={t('settings.paymentPage.invoicesAndReceipts')!}
            className="text-2xl text-primary md:text-3xl"
          />
          <Paragraph
            text={t('settings.paymentPage.seeYourBilling')}
            className="my-5 text-base text-gray-700"
          />
          <div className="custom-scroll overflow-x-auto">
            <table className="custom-scroll w-auto table-fixed overflow-x-auto lg:w-full">
              <thead>
                <tr className="">
                  <th className="w-fit text-left text-lg font-medium text-primary lg:w-1/6 lg:text-xl">
                    {t('settings.paymentPage.date')}
                  </th>
                  <th className="w-fit text-left text-lg font-medium text-primary lg:w-1/6 lg:text-xl">
                    {t('settings.paymentPage.course')}
                  </th>
                  <th className="w-fit text-left text-lg font-medium text-primary lg:w-1/6 lg:text-xl">
                    {t('settings.paymentPage.price')}
                  </th>
                  <th className="w-fit text-left text-lg font-medium text-primary lg:w-2/6 lg:text-xl">
                    {t('settings.paymentPage.order')}#
                  </th>
                  <th className="w-fit text-left text-lg font-medium text-primary lg:w-1/6 lg:text-xl"></th>
                </tr>
              </thead>
              <tbody className="divide-y">{renderSubscriptions()}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
}
