import { ReactElement } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Import Component
import PaymentMessage from 'components/Payment/PaymentMessage';
import { useTranslation } from 'react-i18next';

const PaymentFail = (): ReactElement => {
  const navigate = useNavigate();
  const { programId } = useParams();
  const { t } = useTranslation('pages');

  return (
    <PaymentMessage
      title={t('payment.failed.title')}
      message={t('payment.failed.message')}
      additionalMessage={t('payment.failed.additionalMessage')}
      isSuccess={false}
      button={{
        title: t('payment.failed.tryAgain'),
        onClick: () => navigate(`/checkout/${programId}`),
      }}
    />
  );
};

export default PaymentFail;
