import { ReactElement, useEffect, useRef } from 'react';

// Services
import { FreeContentType } from 'common/types/FreeContent.type';

// Hooks
import { useInnerSize } from 'hooks/elementHooks';

// Components
import { Image } from 'components/Image';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import FreeContentsItems from './FreeContentItems';
import { PageContent } from 'components/Page';

interface Props {
  activeItem: FreeContentType;
  items: FreeContentType[];
  videoClick: (id: string) => void;
}

const FreeContentPlaylist = ({ activeItem, items, videoClick }: Props): ReactElement => {
  // Hooks
  const { elementRef, innerHeight, updateInnerSize } = useInnerSize<HTMLDivElement>();
  const playlistRef = useRef<HTMLDivElement>(null);
  // Handlers
  const playlistScrollToActiveItem = () => {
    if (!activeItem.id) return;
    const itemElement = document.getElementById(activeItem.id);
    // itemEement?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });

    console.log('PLAYLIST_REF', playlistRef.current);

    playlistRef.current?.scrollTo({ top: (itemElement?.offsetTop || 0) - 100, behavior: 'smooth' });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      updateInnerSize();
      playlistScrollToActiveItem();
    }, 200);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [activeItem]);
  return (
    <div className="">
      <div className="relative z-40 -mt-28 h-auto w-full py-8 pt-36">
        <div className="absolute top-0 bottom-0 left-0 right-0 z-0 h-full w-full overflow-hidden">
          <Image
            className="h-full w-full "
            src="/assets/img/course-header.jpg"
            alt="Course Overview"
          />
        </div>
        <PageContent>
          <div className="relative flex flex-col items-start justify-between gap-5 lg:flex-row">
            <div
              ref={elementRef}
              className="w-full overflow-hidden rounded-2xl transition-all duration-100 lg:w-7/12"
            >
              <div className="aspect-w-3 aspect-h-2 w-full overflow-hidden">
                <VideoPlayer
                  data={{
                    path: activeItem.content.data.videoUrl,
                    poster: {
                      path: activeItem.content.data.posterImage,
                      title: activeItem.content.data.posterImageTitle,
                    },
                  }}
                />
              </div>
              <div className="flex flex-col gap-4 bg-black bg-opacity-50 px-7 py-4">
                <h1 className="text-left text-xl font-bold text-white lg:text-3xl">
                  {activeItem.title}
                </h1>
                <h3 className="text-left text-sm text-white">{activeItem.summary}</h3>
              </div>
            </div>
            <div
              style={{ height: `${innerHeight}px` }}
              className="transitiona-all flex h-full w-full flex-col rounded-2xl bg-black bg-opacity-50 p-5 duration-100 lg:max-h-900 lg:w-5/12"
            >
              <h1 className="text-2xl text-white">Browse for more content</h1>
              <div
                ref={playlistRef}
                className="custom-scrollbar-vertical flex flex-col gap-3 overflow-y-auto"
              >
                {/* flex h-full w-full flex-col gap-3 overflow-y-auto rounded-2xl bg-black bg-opacity-50 p-5 lg:max-h-900 */}
                <FreeContentsItems
                  activeItemId={activeItem.id}
                  onItemClick={videoClick}
                  items={items}
                />
              </div>
            </div>
          </div>
        </PageContent>
      </div>
      <PageContent wrapperClassName="bg-white pt-16">
        <div className="flex flex-col gap-10">
          <h1 className="text-4xl font-bold text-primary">More about this topic</h1>
          <p className="text-2xl text-gray-500">{activeItem.description}</p>
        </div>
      </PageContent>
    </div>
  );
};

export default FreeContentPlaylist;
