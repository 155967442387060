import { ReactElement, ReactNode } from 'react';

export interface SubHeaderProps {
  title: string | ReactElement;
  isWhite?: boolean;
  className?: string;
  fontWeight?: 'font-thin' | 'font-light' | 'font-normal' | 'font-medium' | 'font-bold';
  children?: ReactElement | ReactNode | string;
}

export default function SubHeader({
  fontWeight = 'font-medium',
  className,
  title,
  isWhite = false,
  children = null,
}: SubHeaderProps): ReactElement | null {
  if (!title) {
    return null;
  }
  return (
    <h6
      className={`
        text-lg md:text-xl  ${fontWeight}
        ${isWhite ? 'text-white' : ''}
        ${className ? className : ''}
      `}
    >
      <span>{title}</span>
      {children}
    </h6>
  );
}
