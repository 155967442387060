import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

// Import components
import { Image } from 'components/Image';
// import { ReactComponent as CameraIcon } from 'assets/svg-icons/camera-icon.svg';

// import SettingButton from 'components/Settings/Button/SettingButton';

// Import Partials
import MyProfilePage from './MyProfilePage';
import PaymentPage from './PaymentPage';
// import CertificatesPage from './CertificatesPage';
import SettingsEditPage from './SettingsEdit';
import EditProfilePage from './EditProfilePage';
import CoursesPage from './CoursesPage';
import SettingsNavRouteWrapper from './SettingsNavRouteWrapper';

// Import config
import routes from 'config/routesConfig';
import { useTranslation } from 'react-i18next';

// Interfaces
export interface Props {
  className?: string;
}

const SettingsNavigation = ({ className = '' }: Props): ReactElement => {
  const { t } = useTranslation('pages');
  // Declarations
  const linkClasses =
    'rounded-3xl bg-white px-8 py-3 text-center text-xl font-medium text-black shadow drop-shadow mb-3';
  const activeClasses = 'light-blue-gradient text-white';

  return (
    <div className={className}>
      <div className="relative mb-3 overflow-hidden rounded-2xl">
        <Image className="" alt="User Profile" src="/assets/img/user-avatar.png" />
        {/* TODO: When upload image available set user profile */}
        {/* <div className="absolute bottom-0 w-full py-5 bg-black bg-opacity-50">
          <div className="z-10 flex items-center justify-center w-full ">
            <CameraIcon className="mr-2" />
            <span className="text-xl font-bold text-white">Upload a photo</span>
          </div>
          <input className="absolute bottom-0 z-20 w-full h-full opacity-0" type="file" />
        </div> */}
      </div>
      <div className="relative flex flex-col">
        <NavLink
          to={`${routes.settings.index.path}${routes.settings.profile.path}`}
          className={({ isActive }) => `${linkClasses} ${isActive ? activeClasses : ''}`}
        >
          {t('settings.navigation.myProfile')}
        </NavLink>
        <SettingsNavRouteWrapper
          render={() => <MyProfilePage className="my-10 lg:hidden" />}
          path={routes.settings.profile.path}
        />
        <NavLink
          to={`${routes.settings.index.path}${routes.settings.payment.path}`}
          className={({ isActive }) => `${linkClasses} ${isActive ? activeClasses : ''}`}
        >
          {t('settings.navigation.payment')}
        </NavLink>
        <SettingsNavRouteWrapper
          render={() => <PaymentPage className="my-10 lg:hidden" />}
          path={routes.settings.payment.path}
        />
        {/* <NavLink
          to={`${routes.settings.index.path}${routes.settings.certificates.path}`}
          className={({ isActive }) => `${linkClasses} ${isActive ? activeClasses : ''}`}
        >
          Certificates
        </NavLink>
        <SettingsNavRouteWrapper
          render={() => <CertificatesPage className="my-10 lg:hidden" />}
          path={routes.settings.certificates.path}
        /> */}
        <NavLink
          to={`${routes.settings.index.path}${routes.settings.edit.path}`}
          className={({ isActive }) => `${linkClasses} ${isActive ? activeClasses : ''}`}
        >
          {t('settings.navigation.settings')}
        </NavLink>
        <SettingsNavRouteWrapper
          render={() => <SettingsEditPage className="my-10 lg:hidden" />}
          path={routes.settings.edit.path}
        />
        <NavLink
          to={`${routes.settings.index.path}${routes.settings.editProfile.path}`}
          className={({ isActive }) => `${linkClasses} ${isActive ? activeClasses : ''}`}
        >
          {t('settings.navigation.changeYourProfile')}
        </NavLink>
        <SettingsNavRouteWrapper
          render={() => <EditProfilePage className="my-10 lg:hidden" />}
          path={routes.settings.editProfile.path}
        />
        <NavLink
          to={`${routes.settings.index.path}${routes.settings.courses.path}`}
          className={({ isActive }) => `${linkClasses} ${isActive ? activeClasses : ''}`}
        >
          {t('settings.navigation.course')}
        </NavLink>
        <SettingsNavRouteWrapper
          render={() => <CoursesPage className="my-10 lg:hidden" />}
          path={routes.settings.courses.path}
        />
      </div>
    </div>
  );
};

export default SettingsNavigation;
