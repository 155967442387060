import React, { ReactElement, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userAtom } from 'store/userAtom';

// Import components
import { Image } from 'components/Image';
import SubHeader from 'components/SubHeader/SubHeader';
import Paragraph from 'components/Paragraph/Paragraph';
import Header from 'components/Header/Header';

// Import services
import subscriptionService from 'services/subscription.service';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Subscription } from 'common/types/Subscription.type';

// Interfaces
interface Props {
  className: string;
}

export default function CoursesPage({ className }: Props): ReactElement {
  const { t } = useTranslation('pages');

  const [subscriptionStatus, setSubscriptionStatus] = useState(false as boolean);
  const [subscriptions, setSubscriptions] = useState({} as Subscription[]);

  // Effects
  useEffect(() => {
    getStudentSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { user } = useRecoilValue(userAtom);

  const getStudentSubscriptions = async () => {
    const subscriptions = await subscriptionService.getAllSubscriptions(user?.id as string);
    setSubscriptions(subscriptions);
    let subscriptionStatus;
    if (subscriptions) subscriptionStatus = true;
    else subscriptionStatus = false;
    setSubscriptionStatus(subscriptionStatus);
  };

  const renderPrograms = () => {
    return subscriptions.map((subscription) => (
      <Link to={`/course/${subscription.program.courses[0].id}/overview`}>
        <div
          className="relative flex flex-col overflow-hidden rounded-2xl.5 bg-white lg:flex-row"
          style={{ marginBottom: '20px' }}
        >
          <div className="w-full lg:w-96">
            {subscription.program.courses[0].overview && (
              <Image
                src={subscription.program.courses[0].overview.thumbnailUrl || ''}
                alt={subscription.program.title || ''}
              />
            )}
          </div>
          <div className="flex-1 p-3 lg:p-7">
            <div className="mb-5 flex justify-between">
              <SubHeader title={subscription.program.courses[0].title} />
              <span className="flex text-gray-700">
                {t('settings.coursesPage.status')}
                <span className="ml-3 font-medium text-primary">
                  {t('settings.coursesPage.active')}
                </span>
              </span>
            </div>
            <Paragraph text={subscription.program.courses[0].description} />
          </div>
        </div>
      </Link>
    ));
  };

  if (subscriptionStatus === false)
    return (
      <div className={`${className && className} relative`}>
        <Header title={t('settings.coursesPage.myCourses')} className="mb-5" />
        <span className="pb-3 text-base text-gray-700 md:text-2xl.5 lg:pb-0">
          {t('settings.coursesPage.notPaid')}
        </span>
      </div>
    );
  else
    return (
      <div className={`${className && className} relative`}>
        <Header title={t('settings.coursesPage.myCourses')} className="mb-5" />
        {renderPrograms()}
      </div>
    );
}
