import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Import types
import { Course as CourseType } from 'common/types/Course.type';

// Import services
import courseService from 'services/course.service';

// Import component
import { CourseHeader } from 'components/Courses/CourseHeader';
import CourseDetails from 'components/Courses/CourseDetails/CourseDetails';

export default function Course(): ReactElement {
  // State
  const [error, setError] = useState('');
  const [loadinStudentSubscription, setLoadingStudentSubscription] = useState(false);
  const [course, setCourse] = useState<CourseType | null>(null);
  const { courseId } = useParams();
  const loading = loadinStudentSubscription;

  // Handlers
  const handleGetCourse = async () => {
    setLoadingStudentSubscription(true);
    setError('');
    try {
      const course = await courseService.getCourse(courseId as string);
      setCourse(course);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setError(error.message);
    }
    setLoadingStudentSubscription(false);
  };

  // Effects
  useEffect(() => {
    handleGetCourse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId]);

  return (
    <div className="bg-gray-600">
      {course?.overview && (
        <CourseHeader
          data={{
            ...course,
            ...course.overview,
            header: course.title,
            price: course.program.price,
          }}
        />
      )}
      {course && <CourseDetails course={course} loading={loading} error={error} />}
    </div>
  );
}
