import { ReactElement, useState } from 'react';

// Import types
import { CarouselSectionData, ImageOrVideoType } from 'common/types/RenderEngine.type';

// Import Components
import SubHeader from 'components/SubHeader/SubHeader';
import Header from 'components/Header/Header';
import { Image } from 'components/Image';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
export interface CarouselSectionProps {
  data: CarouselSectionData;
}

function CarouselSection({
  data: { header, subHeader, media, showIndex = false },
}: CarouselSectionProps): ReactElement<CarouselSectionProps> {
  // State
  const [show, setShow] = useState(false);
  // Handle
  const handleIndex = () => {
    setShow(!show);
  };

  // Renderers
  const renderMedia: () => ReactElement[] | null = () => {
    if (!media?.length) return null;
    return media.map(({ type, id, image, video, title = '' }: ImageOrVideoType, index) => {
      return (
        <div
          className="w-9/12 flex-none md:w-1/4"
          key={`carousel_${type}_+${image?.id}_+${video?.path}_+${id}`}
        >
          {type === 'ImageType' && image ? (
            <div>
              <div className="aspect-w-16 aspect-h-9 relative overflow-hidden rounded-2xl.5">
                <Image className="z-0" src={image?.path || ''} alt={title || ''} />
                {showIndex && (
                  <>
                    <div className="absolute h-doublefull w-full -translate-x-1/2 -rotate-113 transform bg-blue-100 bg-opacity-65"></div>
                    <div
                      className="absolute left-4 bottom-4 top-unset h-fit w-fit text-8xl text-white lg:bottom-10 lg:left-10 lg:text-1xxl"
                      style={{ lineHeight: '.65' }}
                    >
                      <span>{index + 1}</span>
                    </div>
                  </>
                )}
              </div>
              {title && (
                <SubHeader
                  className="mt-5 text-left"
                  title={
                    <span className="flex justify-start">
                      <span>{title}&nbsp; </span>
                      <span> #{index + 1}</span>
                    </span>
                  }
                />
              )}
            </div>
          ) : null}
          {type === 'VideoPlayerType' && video ? (
            <div>
              <div className="aspect-w-16 aspect-h-9 relative overflow-hidden rounded-2xl.5">
                <VideoPlayer
                  onVideoPlay={handleIndex}
                  className={`${show ? 'z-20' : 'z-0'}`}
                  data={{ ...video }}
                />
                {showIndex && (
                  <>
                    <div className="absolute h-doublefull w-full -translate-x-1/2 -rotate-113 transform bg-blue-100 bg-opacity-65"></div>
                    <div
                      className="absolute left-4 bottom-4 top-unset h-fit w-fit text-8xl text-white lg:bottom-10 lg:left-10 lg:text-1xxl"
                      style={{ lineHeight: '.65' }}
                    >
                      <span>{index + 1}</span>
                    </div>
                  </>
                )}
              </div>
              {title && (
                <SubHeader
                  className="mt-5 text-left"
                  title={
                    <span className="flex justify-start">
                      <span>{title}&nbsp; </span>
                      <span>#{index + 1}</span>
                    </span>
                  }
                />
              )}
            </div>
          ) : null}
        </div>
      );
    });
  };

  return (
    <div className="py-20 text-center">
      {subHeader && <SubHeader title={subHeader} />}
      {header && <Header title={header} />}
      {media?.length ? (
        <div className="relative mt-10 flex w-full justify-center pl-4 md:pl-36 ">
          <div className="carousel ml-0 gap-5 pr-0 transition-all">{renderMedia()}</div>
        </div>
      ) : null}
    </div>
  );
}

export default CarouselSection;
