import React, { ReactElement } from 'react';

import SubHeader from 'components/SubHeader/SubHeader';
import Header from 'components/Header/Header';
import Paragraph from 'components/Paragraph/Paragraph';
import { Button } from 'components/Button';
// Import type
import { TextSectionData } from 'common/types/RenderEngine.type';
export interface TextSectionProp {
  data: TextSectionData;
}
export default function TextSection({
  data: { header, subHeader, paragraph, callToAction },
}: TextSectionProp): ReactElement<TextSectionProp> {
  // Handlers
  const handleCallToAction = () => {
    if (!callToAction) return null;
    window.open(callToAction.path, '_blank');
  };
  return (
    <div className="mx-auto w-full px-4 py-20 text-center lg:px-72 ">
      {subHeader && <SubHeader className="mb-5" title={subHeader} />}
      {header && <Header className="mb-10" title={header} />}
      {paragraph && (
        <Paragraph textAlign="center" className="mb-10 text-left leading-8" text={paragraph} />
      )}
      {callToAction ? (
        <Button
          round="4xl"
          size="2xl"
          title={callToAction?.label}
          background="green"
          className="mx-auto"
          color="white"
          onClick={handleCallToAction}
        />
      ) : null}
    </div>
  );
}
