import { FC, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// Import services
import authService from 'services/auth.service';
import userService from 'services/user.service';

// Import types
import { CreateUser } from 'common/types/User.type';

// Import components
import { Input } from 'components/Form/Input';
import { Alert } from 'components/Alert';
import { Button } from 'components/Button/index';
import { Checkbox } from 'components/Form/Checkbox';

// Import partials
import SignupLoginLinks from '../partials/_SignupLoginLinks';

// Import state
import { userAuth } from 'store/auth';
import { userAtom } from 'store/userAtom';
import { useTranslation } from 'react-i18next';

// Form schema
const schema = yup.object().shape({
  email: yup.string().email('Vennligst skriv inn en gyldig e-postadresse').required('Epost kreves'),
  password: yup
    .string()
    .required('Passord kreves!')
    .min(6, 'Passordet må være på minst 6 tegn!')
    .max(20, 'Passordet må være mindre enn 20 tegn!'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passord må være like!'),
  profile: yup.object().shape({
    firstName: yup.string().required('Fornavn kreves!'),
    lastName: yup.string().required('Etternavn krevers!'),
  }),
});

const Signup: FC = () => {
  // Sate
  const setUserAuth = useSetRecoilState(userAuth);
  const setUser = useSetRecoilState(userAtom);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const userLanguage = navigator.language;
  const languageCode = userLanguage.split('-')[0];

  const { t } = useTranslation('pages');
  // Form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateUser>({ resolver: yupResolver(schema) });
  const onSubmit = async (data: CreateUser) => {
    setLoading(true);
    setError('');
    await authService
      .signUp(data)
      .then((response) => {
        setUserAuth({
          isAuthenticated: true,
        });
        setUser({ user: authService.getUser() });
      })
      .catch((error) => {
        setError(error.responseMessage);
      });
    // set the user profile
    const newUserProfile = await userService.getUserByEmail(authService.getUser()?.email || '');
    setUser({ user: newUserProfile });
    setLoading(false);
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  // Renderers
  return (
    <div className="relative">
      <img src="/mtg-logo.png" className="mx-auto" alt="MTG Logo" />
      <div className="mx-auto my-5 w-11/12 rounded-4xl bg-white bg-opacity-75 bg-cover px-8 py-10 shadow backdrop-blur backdrop-filter md:mx-auto md:w-460">
        <p
          aria-label={t('signup.title')!}
          className="mb-5 text-center text-2xl font-bold leading-6 text-gray-800"
        >
          {t('signup.title')}
        </p>
        <div className="my-5 flex w-full items-center justify-between py-5"></div>
        <form className="text-center" onSubmit={handleSubmit(onSubmit)}>
          <Alert message={error} />
          <div className="flex w-full flex-nowrap justify-between">
            <div className="w-2/4">
              <Input
                placeholder={t('signup.firstName')}
                type="text"
                {...register('profile.firstName')}
                errors={errors}
              />
            </div>
            <div className="w-2/4">
              <Input
                placeholder={t('signup.lastName')}
                type="text"
                {...register('profile.lastName')}
                errors={errors}
              />
            </div>
          </div>
          <div className="mt-5 w-full">
            <Input
              autoComplete="email"
              type="email"
              {...register('email')}
              placeholder={t('signup.email')}
              errors={errors}
            />
          </div>
          <div className="mt-5 w-full">
            <Input
              autoComplete="off"
              type="password"
              placeholder={t('signup.password')}
              {...register('password')}
              errors={errors}
            />
          </div>
          <div className="mt-5 w-full">
            <Input
              autoComplete="off"
              type="password"
              placeholder={t('signup.confirmPassword')}
              {...register('confirmPassword')}
              errors={errors}
            />
          </div>
          <div className="mt-5 w-full">
            <Checkbox
              checked={isChecked}
              label={t('signup.terms-of-use.message')}
              link={
                languageCode === 'en'
                  ? 'https://madetogrow.no/personvernerklaering/en'
                  : 'https://madetogrow.no/personvernerklaering/'
              }
              textLink={t('signup.terms-of-use.link') || ''}
              afterTextLink="."
              name="terms-of-use"
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="my-5">
            <Button
              disabled={loading || !isChecked}
              background="green"
              color="white"
              type="submit"
              title={t('signup.signup')!}
              full={true}
              round="4xl"
              size="xl"
            />
          </div>
        </form>
      </div>
      <SignupLoginLinks />
    </div>
  );
};

export default Signup;
