import axios from 'axios';

// Config
import { configuration } from 'config/config';

// Types
import { BlogPost } from 'common/types/Blog.type';
import { ListResult } from 'common/types/ListResult.type';

class BlogService {
  getBlogPosts = async (categories = [28]): Promise<ListResult<BlogPost>> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res: any = await axios.get(
      `${configuration().blogApiUrl}posts?categories=${categories.join('')}`
    );
    if (!res.data?.length) return { items: [] };
    const blogPosts = res.data.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (post: any) =>
        ({
          id: post.id,
          title: post.yoast_head_json.title.replace(' - MadeToGrow', ''),
          contentUrl: post.yoast_head_json.og_url,
          description: post.yoast_head_json.description,
          imagePath: post.yoast_head_json.og_image[0].url,
        } as BlogPost)
    );

    return {
      items: blogPosts,
    };
  };
}

export default new BlogService();
