import { FeaturedEnum, FeaturedType } from 'common/types/FeaturedContent.type';
import { Link } from 'react-router-dom';

// Components
import { Image } from 'components/Image';
import { SvgIcon, SvgIconList } from 'components/Icon';

interface Props {
  items: FeaturedType[];
}

export const FeaturedContentSlider = ({ items }: Props) => {
  return (
    <div className="carousel gap-5 py-8">
      {items.map(({ id, imagePath, title, type }, index) => {
        const baseUrl = type === FeaturedEnum.COURSE ? '/course' : '/content';
        return (
          <div key={id}>
            <Link to={`${baseUrl}/${id}`} className="relative h-full">
              <div className="item aspect-w-16 aspect-h-7 relative flex h-full w-full min-w-520 items-center justify-center overflow-hidden rounded-xl px-5 align-middle">
                <Image alt={title} src={imagePath} />

                <div className="light-blue-gradient h-full w-full opacity-0 transition-opacity duration-200 hover:opacity-20"></div>

                {type === FeaturedEnum.FREE_CONTENT && (
                  <SvgIcon
                    className="pointer-events-none flex h-full w-full items-center justify-center align-middle"
                    width={'20%'}
                    height="20%"
                    name={SvgIconList.CONTENT_PLAY_BUTTON}
                  />
                )}

                <div className="pointer-events-none absolute flex h-full w-full items-end py-3 px-5">
                  <span className="text-lg font-bold text-white drop-shadow-lg">{title}</span>
                </div>
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default FeaturedContentSlider;
