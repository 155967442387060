import { ReactElement, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRecoilValue } from 'recoil';

// Import recoil atoms
import { userAtom } from 'store/userAtom';

// Import types
import { ChangePasswordData } from 'common/types/User.type';

// Import services
import userService from 'services/user.service';

// Import Components
import SubHeader from 'components/SubHeader/SubHeader';
import Header from 'components/Header/Header';
import { Input } from 'components/Form/Input';
// import { Checkbox } from 'components/Form/Checkbox';
import { ReactComponent as EditIcon } from 'assets/svg-icons/edit-icon.svg';
import { Alert, AlertType } from 'components/Alert';
import { useTranslation } from 'react-i18next';

const formSchema = yup.object().shape({
  password: yup
    .string()
    .required('Passord kreves!')
    .min(6, 'Passordet må være på minst 6 tegn!')
    .max(20, 'Passordet må være mindre enn 20 tegn!'),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passord må være like!'),
});

interface Props {
  className?: string;
}

const SettingsEditPage = ({ className = '' }: Props): ReactElement => {
  const { t } = useTranslation('pages');
  const { user } = useRecoilValue(userAtom);

  // State
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  // Form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangePasswordData>({ resolver: yupResolver(formSchema) });

  // Handlers
  const handleChangePasswordSubmit = async (data: ChangePasswordData) => {
    try {
      const message = await userService.changePassword(data);
      setMessage(message);
      setTimeout(() => {
        navigate('/settings/profile');
      }, 2000);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setError(error.message);
    }
  };

  return (
    <div className={className}>
      <Header className="mb-5 text-26 " title={t('settings.changePasswordForm.myMTGProfile')} />
      <div className="relative mb-5 rounded-4xl bg-transparent bg-white lg:bg-white lg:p-7 lg:shadow-md">
        <SubHeader
          className="mb-3 text-2xl text-primary md:text-3xl"
          title={t('settings.changePasswordForm.email')!}
        />
        <div className="mb-5 flex items-center justify-between">
          <div className="flex w-full flex-1 flex-col rounded-2xl bg-white pb-3 lg:mr-5 lg:flex-row lg:py-3 lg:px-5 lg:shadow">
            <span className="pb-3 text-base text-gray-700 md:text-2xl.5 lg:pb-0">
              {t('settings.changePasswordForm.yourEmail')}:&nbsp;
            </span>
            <span className=" flex w-full items-center break-all rounded-2xl px-5 py-3 text-lg font-medium text-primary shadow lg:w-fit lg:rounded-none lg:py-0 lg:px-0 lg:text-2xl.5 lg:shadow-none">
              {user?.email}
            </span>
          </div>
          {/* <div className="self-stretch hidden px-5 py-3 light-blue-gradient rounded-2xl lg:flex">
            <span className="flex items-center font-medium text-white">Edit Email Address</span>
          </div> */}
          <Link
            className="absolute top-0 right-0 -translate-y-1/2 transform rounded-2xl bg-blue-200 p-3 lg:hidden"
            to="#"
          >
            <EditIcon />
          </Link>
        </div>
        <SubHeader className="mb-3 text-2xl text-primary md:text-3xl" title="Passord" />
        <Alert message={error} />
        <Alert type={AlertType.SUCCESS} message={message} />
        <form onSubmit={handleSubmit(handleChangePasswordSubmit)} className="flex flex-col">
          <Input
            autoComplete="off"
            type="password"
            placeholder={t('settings.changePasswordForm.entryPassword')}
            className="mb-5"
            inputClassName="w-full leading-none rounded-2xl bg-white py-3 px-8 text-lg shadow lg:text-2xl.5"
            removeDefaultInputClass
            {...register('password')}
            errors={errors}
          />
          <Input
            autoComplete="off"
            type="password"
            placeholder={t('settings.changePasswordForm.confirmPassword')}
            className="mb-5"
            inputClassName="w-full leading-none rounded-2xl bg-white py-3 px-8 text-lg shadow lg:text-2xl.5"
            removeDefaultInputClass
            {...register('passwordConfirmation')}
            errors={errors}
          />
          <button className="light-blue-gradient self-center rounded-2xl.5 px-10 py-6 text-xl font-medium text-white">
            {t('settings.changePasswordForm.changePassword')}
          </button>
        </form>
      </div>
      {/* <div className="bg-transparent bg-white rounded-4xl lg:bg-white lg:p-7 lg:shadow-md">
        <SubHeader title="Privacy" className="text-2xl md:text-3xl " />
        <div className="mt-5">
          <Checkbox
            className="mb-3"
            label="Show your profile to logged-in users"
            name="logged-in"
          />
          <Checkbox
            checked
            label="Show courses you're taking on your profile page"
            name="show-courses"
          />
        </div>
      </div> */}
    </div>
  );
};

export default SettingsEditPage;
