import { FC, ReactElement } from 'react';

// Import containers
import { Navigation } from 'containers/Navigation';
import { Footer } from 'pages/Footer';

export interface MainLayoutProps {
  children: ReactElement;
}

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  return (
    <div className="flex w-full flex-col">
      <Navigation />
      <div className="z-40" id="main-layout__portal-container" />
      <div className="flex min-h-screen flex-col pt-28">
        <div className="flex flex-grow flex-col">{children}</div>
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;
