import api from 'utils/api';

// Import types
import { ListResult } from 'common/types/ListResult.type';
import { Course } from 'common/types/Course.type';

export type StudentCourseEnrolledTypes = 'ALL' | 'ENROLLED' | 'NOT_ENROLLED';

interface GetCoursesQuery {
  enrolled?: StudentCourseEnrolledTypes;
}

class CoursesService {
  getCourses = async ({ enrolled = 'ALL' }: GetCoursesQuery): Promise<ListResult<Course>> => {
    const res: ListResult<Course> = await api.get(`api/student/courses?enrolled=${enrolled}`);
    return res;
  };

  getCourse = async (id: string): Promise<Course> => {
    // eslint-disable-next-line no-invalid-this
    if (id === 'main') return this.getMainCourse();
    const res: Course = await api.get(`api/student/course/${id}`);
    return res;
  };

  getMainCourse = async (): Promise<Course> => {
    const res: Course = await api.get('api/student/course/main');
    return res;
  };
}

export default new CoursesService();
