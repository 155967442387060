import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export interface Props {
  className?: string;
}

export default function Footer({ className = '' }: Props): ReactElement {
  const { t } = useTranslation('pages');
  return (
    <div>
      {/*
      <div
        className={`z-50 flex flex-col items-center justify-between bg-white px-4 py-2 md:flex-row ${className}`}
      >
        <div className="transition duration-150 transform hover:scale-105 md:mr-6">
          <Link to="/">
            <img src="/assets/img/mtg-black-logo.svg" className="" alt="mtg-black" />
          </Link>
        </div>
        <div className="flex-none w-full mt-10 md:mt-0 md:w-2/3">
          <ul className="flex flex-col items-center content-center flex-1 gap-3 md:flex-row md:justify-end">
            <li className="transition duration-150 transform hover:scale-105 md:mr-6">
              <a
                className="hover:shadow-md"
                href="https://madetogrow.no/"
                target="_blank"
                rel="noreferrer"
              >
                MadeToGrow Hjem
              </a>
            </li>
            <li className="transition duration-150 transform hover:scale-105 md:mr-6">
              <a className="hover:shadow-md" href="/settings/profile">
                Konto
              </a>
            </li>

            <li className="transition duration-150 transform hover:scale-105 md:mr-6">
              <a
                className="hover:shadow-md"
                href="https://madetogrow.no/om-oss/"
                target="_blank"
                rel="noreferrer"
              >
                Om Oss
              </a>
            </li>
            <li className="transition duration-150 transform hover:scale-105 md:mr-6">
              <a
                className="hover:shadow-md"
                href="https://madetogrow.no/kontakt/"
                target="_blank"
                rel="noreferrer"
              >
                Kontakt Oss
              </a>
            </li>
          </ul>
        </div>
      </div>*/}
      <div
        className={`z-50 flex flex-col items-center justify-between bg-white px-4 py-6 ${className}`}
      >
        <div className="">
          <a
            className="hover:shadow-md"
            href="https://madetogrow.no/personvernerklaering/"
            rel="noreferrer"
          >
            {t('footer.conditions')}
          </a>
        </div>
        <p
          className={`z-50 flex flex-col items-center justify-between bg-white px-4 py-2 ${className}`}
        >
          {t('footer.footerText')}
        </p>
      </div>
    </div>
  );
}
