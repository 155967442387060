import React, { ReactElement } from 'react';

// Types
import { FontWeights } from 'common/types/style.type';

// Interfaces
export interface HeaderProps {
  title: string;
  isWhite?: boolean;
  className?: string;
  fontWeight?: FontWeights;
}

export default function Header({
  fontWeight = 'font-medium',
  className = '',
  title,
  isWhite = false,
}: HeaderProps): ReactElement {
  return (
    <h2
      style={{ lineHeight: '1.6' }}
      className={`text-3xl ${fontWeight} md:text-4xl.5
        ${isWhite ? 'text-white' : 'text-primary'}
        ${className}
      `}
    >
      {title}
    </h2>
  );
}
