import api from 'utils/api';

// Import types
import { ChangePasswordData, ChangeProfileData, User } from 'common/types/User.type';
import { UserCheckboxSectionData } from 'common/types/UserLessonData.type';

class UserService {
  getUserByEmail = async (email: string): Promise<User> => {
    return await api.get('/api/user/by-email/' + email);
  };

  saveLessonData = async (data: UserCheckboxSectionData): Promise<void> => {
    await api.post('/api/user-lesson-data', data);
  };

  changePassword = async (data: ChangePasswordData): Promise<string> => {
    return await api.post('/api/user/change-password', data);
  };

  patchProfileData = async (userId: string, data: ChangeProfileData): Promise<string> => {
    return await api.patch('/api/profile/' + userId, data);
  };
}

export default new UserService();
