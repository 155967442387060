import React, { ReactElement, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

interface Props {
  show?: boolean;
  className?: string;
  portalContainerId?: string;
  onClick?: () => null | undefined | void;
}

function Backdrop({
  show = true,
  className = '',
  portalContainerId = '',
  onClick,
}: Props): ReactElement | null {
  const [showBackdrop, setShowBackdrop] = useState(show);
  const [animate, setAnimate] = useState(false);
  const clickable = onClick ? true : false;

  // Effects
  useEffect(() => {
    setAnimate(show);
    const timeout = setTimeout(() => {
      setShowBackdrop(show);
    }, 150);

    return () => clearTimeout(timeout);
  }, [show]);

  // Renderers
  if (!showBackdrop && !animate) return null;

  const showClasses = animate ? 'backdrop-blur' : 'backdrop-blur-0';
  const content = (
    <div className="relative">
      <span
        onClick={clickable ? () => onClick && onClick() : () => null}
        className={`
        fixed top-0 left-0 right-0 bottom-0 z-50 h-screen w-full overflow-hidden bg-transparent backdrop-filter transition-all duration-150
        ${clickable ? '' : 'pointer-events-none'}
        ${className}
        ${showClasses}
        `}
      />
    </div>
  );
  if (!portalContainerId) return content;
  return createPortal(content, document.getElementById(portalContainerId)!);
}

export default Backdrop;
