import { ReactElement } from 'react';

interface Props {
  show?: boolean;
}

const AbsoluteSpinner = ({ show = false }: Props): ReactElement => {
  if (!show) return <></>;
  return (
    <div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex justify-center align-middle">
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-gray-200 opacity-70" />
      <div className="flex items-center justify-center">
        {/* <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-green-700"></div> */}
        <div className="flex animate-pulse items-center justify-center space-x-2">
          <div
            style={{ borderTopColor: 'transparent' }}
            className="h-16 w-16 animate-spin rounded-full border-4 border-dashed border-blue-400"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default AbsoluteSpinner;
