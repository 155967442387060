import axios, { AxiosError, AxiosResponse } from 'axios';
import { NavigateFunction } from 'react-router-dom';
import authService from '../services/auth.service';

export interface ApiConfig {
  onSignOut?: () => void;
  navigate?: NavigateFunction;
}

const defaultConfig: ApiConfig = {
  onSignOut: () => null,
};

let config: ApiConfig = {
  ...defaultConfig,
};

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
});

API.interceptors.request.use((request) => {
  if (!request.headers) {
    request.headers = {};
  }
  request.headers.Accept = 'application/json';

  const jwtToken = authService.getToken();
  if (jwtToken) {
    // Automatically attach JWT for authenticated API requets
    request.headers.Authorization = `Bearer ${jwtToken}`;
  }

  if (request.method === 'POST' || request.method === 'PUT') {
    // Let the backend know we're sending JSON data
    request.headers['Content-Type'] = 'application/json';
  }

  return request;
});

export interface APIError extends AxiosError {
  statusCode?: number;
  responseMessage: string;
}

API.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      authService.signOut();
      config.onSignOut!();
    }

    if (error.response?.status === 404) {
      config?.navigate?.('/');
    }

    const apiError = {
      ...error,
      code: error.code || error.response?.data?.statusCode || error.response?.data?.code,
      message: error.message || error.response?.data?.message,
      statusCode: error.response?.data?.statusCode,
      responseMessage: error.response?.data?.message,
    };
    return Promise.reject(apiError);
  }
);

export const configureAPI = (newConfig: ApiConfig): void => {
  config = { ...config, ...newConfig };
};

export default API;
