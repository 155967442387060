import api from 'utils/api';

// Types
import { ListResult } from 'common/types/ListResult.type';
import { FeaturedType } from 'common/types/FeaturedContent.type';

class ContentService {
  async getFeaturedContent(): Promise<ListResult<FeaturedType>> {
    const freeContents: ListResult<FeaturedType> = await api.get('/api/content/featured');
    return freeContents;
  }
}
export default new ContentService();
