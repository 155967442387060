import { ReactElement, useState } from 'react';
import Header from 'components/Header/Header';
import SubHeader from 'components/SubHeader/SubHeader';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';

// Import types
import { VideoSectionData } from 'common/types/RenderEngine.type';

export interface VideoSectionProps {
  data: VideoSectionData;
}

export default function VideoSection({
  data: { header, subHeader, video },
}: VideoSectionProps): ReactElement {
  const [isPlaying, setIsPlaying] = useState(false);
  // Handlers
  const handleOnVidePlay = () => {
    setIsPlaying(true);
  };
  return (
    <div className="relative z-40 mt-20 overflow-hidden">
      {!isPlaying && (
        <div className="absolute left-4 top-40 z-30 h-fit w-9/12 text-left lg:left-36 lg:w-1/3">
          {header && <Header isWhite className="mb-5" title={header} />}
          {subHeader && <SubHeader isWhite title={subHeader} />}
        </div>
      )}
      {video && (
        <div className="aspect-w-2 aspect-h-1 relative xl:aspect-w-3 xl:aspect-h-1">
          <VideoPlayer onVideoPlay={handleOnVidePlay} className="w-full object-fill" data={video} />
        </div>
      )}
    </div>
  );
}
